import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import DefaultButton from '../../components/button';
import Button from '../../components/button';
import { ROUTES } from '../../components/router/routes';
import { Link } from 'react-router-dom';
const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
    width: '100%'
  },
  titleContainer: {
    marginBottom: '2em'
  },
  container: {
    width: '100%',
    marginBottom: '2em'
  },
  profileBox: {
    background: '#eee',
    padding: '5px',
    maxWidth: 500,
    margin: '0 auto',
    marginTop: '1em'
  },
  pic: {
    maxHeight: 150,
    borderRadius: '50%',
    border: '3px solid #ccc'
  },
  assignBtn: {
    marginTop: '1em'
  },
  options: {
    display: 'flex',
    flexDirection: 'column'
  },
  menu: {
    marginTop: '0.5em',
    marginBottom: '3em'
  }
});

export default function Profiles(props) {
  const classes = useStyles();
  const profile = props.location.state && props.location.state.data

  return (
    <div className="profiles">

      <div className={classes.menu}>
        <Link  
          to={{
            pathname: ROUTES.PROFILES,
          }}
        >
          <Button> View All Profiles </Button>
        </Link>
      </div>

      <div className={classes.container}>
        <img className={classes.pic} src={profile.picture_url} />
        <p>{profile.display_name}</p>
      </div>
      <div className={classes.options}>
        <Link  
          to={{
            pathname: ROUTES.EDITPROFILE,
            state: { data: profile }
          }}
        >
          <Button> Edit Profile </Button>
        </Link>
        <a target='_blank' href={`https://creatorset.com/pages/workshop/${profile.id}`}>
          <Button> View Public Profile </Button>
        </a>
      </div>
    </div>
  );
}