import { handleResponse, URLS, getAccessToken } from "../../utils";

export async function getOrderById(orderId) {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + "/getOrderById?q=" + orderId;
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getDownloadLink(orderId) {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + "/getDownloadLink?id=" + orderId;
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getSingleDownloadLink(id, itemId) {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + `/getSingleDownloadLink?id=${id}&itemId=${itemId}`;
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function updateProperties(id, properties) {
  const req = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      id,
      properties
    })
  };
  const url = URLS.PUBLIC_API + "/updateProperties";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function sendRerenderRequest(id, itemIds) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      id,
      itemIds
    })
  };
  const url = URLS.PUBLIC_API + "/rerender";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function createWebmRequest(orderId) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      orderId
    })
  };
  const url = URLS.PUBLIC_API + "/createWebm";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function sendUpdatedEmail(id) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      id,
    })
  };
  const url = URLS.PUBLIC_API + "/sendUpdatedEmail";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getRenderMessage(orderId) {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + "/getRenderMessage?id=" + orderId;
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getAllProfiles() {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + "/get-profiles";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getAllProfilesNew() {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + "/get-profiles-new";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function sendToQueueRequest(id) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      id,
    })
  };
  const url = URLS.PUBLIC_API + "/sendToQueue";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function updateEmail(id, email) {
  const req = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      id,
      email
    })
  };
  const url = URLS.PUBLIC_API + "/updateEmail";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getUploadUrl(id, secret) {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + "/getUploadUrl?id=" + id + "&m=" + secret;
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function newProfile(formData) {
  const req = {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
  const url = URLS.PUBLIC_API + "/new-profile";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function updateProfile(formData) {
  const req = {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'BEARER ' + getAccessToken(),
        'Content-Type': 'application/json',
      },
    };
  const url = URLS.PUBLIC_API + "/update-profile";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function uploadFileChunk(fileChunk, signedUrl, onProgress, isAttachment = false) {
  const promise = new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open("PUT", signedUrl);

      xhr.setRequestHeader('Content-Type', 'application/zip');

      if (isAttachment) {
          //xhr.setRequestHeader('Content-Disposition', 'attachment;');
      }

      const start = new Date();
      
      xhr.upload.onprogress = function (e) {
          if (e.lengthComputable)
          {
              const percentage = Math.round((e.loaded/e.total)*100);
              if (onProgress) {
                  const now = new Date();
                  const timeElapsed = (now - start) / 1000; //because its in ms
                  const timeLeft = (timeElapsed * (e.total - e.loaded)) / e.loaded;
                  onProgress(percentage, e.loaded, timeLeft);
              }
          }
      }
      xhr.onloadend = function (e) {
          resolve(true);
      }
      xhr.onerror = function(e) {
        console.log(e);
          reject(e);
      }
      xhr.send(fileChunk);
  });

  const response = await promise;
  //TODO: handle error.
  return { result: response };
}

export async function updateProfileNew(formData) {
  const req = {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
  const url = URLS.PUBLIC_API + "/update-profile-new";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}