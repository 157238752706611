import {
  GET_ALL_REVIEWABLE_PRODUCTS,
  GET_ALL_REVIEWABLE_PRODUCTS_SUCCESS,
  APPROVE_PRODUCT,
  APPROVE_PRODUCT_SUCCESS,
  REJECT_PRODUCT,
  REJECT_PRODUCT_SUCCESS,
  DISMISS_MESSAGES,
} from "./actionTypes";

export const getAllReviewableProducts = () => {
  return {
    type: GET_ALL_REVIEWABLE_PRODUCTS,
  };
};

export const approveProduct = ({ id }) => {
  return {
    type: APPROVE_PRODUCT,
    product_id: id,
  };
};

export const rejectProduct = ({ id, reason }) => {
  return {
    type: REJECT_PRODUCT,
    product_id: id,
    reason: reason,
  };
};

export const getAllReviewableProductsSuccess = (reviewableProducts) => {
  return {
    type: GET_ALL_REVIEWABLE_PRODUCTS_SUCCESS,
    reviewableProducts,
  };
};

export const approveProductSuccess = (message) => {
  return {
    type: APPROVE_PRODUCT_SUCCESS,
    message,
  };
};

export const rejectProductSuccess = (message) => {
  return {
    type: REJECT_PRODUCT_SUCCESS,
    message,
  };
};

export const dismissMessages = () => {
  return {
    type: DISMISS_MESSAGES,
  };
};
