import { getAccessToken, handleResponse, URLS } from "../../utils";

export async function newGS(formData) {
  const req = {
    method: "POST",
    body: formData,
  };
  const url = URLS.PUBLIC_API + "/new-gs";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getFormEntities() {
  const req = {
    method: "GET",
  };

  const url = URLS.PUBLIC_API + "/get-form-entities";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getProductDownloadLink(body) {
  const req = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "BEARER " + getAccessToken(),
    },
  };

  const url = URLS.PUBLIC_API + "/get-pdl";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function prepareUpdateFile(body) {
  const req = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "BEARER " + getAccessToken(),
    },
  };

  const url = URLS.PUBLIC_API + "/prepare-update-file";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function saveAnimationInstructions(body) {
  const req = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "BEARER " + getAccessToken(),
    },
  };

  const url = URLS.PUBLIC_API + "/save-instructions";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getAnimationInstructions(id) {
  const req = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "BEARER " + getAccessToken(),
    },
  };

  const url = URLS.PUBLIC_API + "/load-instructions?id=" + id;
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function getProductById(id) {
  const req = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "BEARER " + getAccessToken(),
    },
  };

  const url = URLS.PUBLIC_API + "/get-product/" + id;
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}
