import { takeLatest, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { newGS, getFormEntities, getProductDownloadLink, prepareUpdateFile, getAnimationInstructions, saveAnimationInstructions } from './api';
import * as actions from './actions';
import { customHistory } from '../../components/router/router';
import { uploadFileChunk } from '../profileService/api';

function* watchNewGS(action) {
    try {
        const { data, setLoading } = action;
        const formData = new FormData();

        for(const name in data) {
            formData.append(name, data[name]);
        }

        const r = yield call(newGS, formData);

        if (r) {
            yield call(setLoading, false);
            yield call(customHistory.push, "/orders");
            alert("Product created.");
        }
    }
    catch(e) {

    }
}

function* watchGetFormEntities(action) {
    try {
        console.log("HERE");
         const r = yield call(getFormEntities);
         yield put(actions.getFormEntitiesRequestSuccess( r.result ));
        
    }
    catch(e) {

    }
}


function* watchGetPDL(action) {
    const { sku, pwd, setLoading, callback } = action;
    try {
         const r = yield call(getProductDownloadLink, { sku, pwd });
         yield put(actions.getProductDLinkSuccess( r.result ));
         yield call(setLoading, false);

         callback(r)
        //  if (r.result != "Invalid Request.") {
        //     var copyText = document.getElementById("download-url");
        //     copyText.value = r.result; 
        //     copyText.select(); 
        //     copyText.setSelectionRange(0, 99999);
        //  }
    }
    catch(e) {
        console.log(e);
        yield put(actions.getProductDLinkSuccess( "Invalid request!" ));
        yield call(setLoading(false));
    }
}

function* watchUpdateProductFile(action) {
    const { sku, file, setProgress, pwd, setLoading } = action;
    try {
        //get upload url;
        const { result: presignedUrl } = yield call(prepareUpdateFile, { sku, pwd });

        const onProgressCb = (percentage) => {
            setProgress(percentage);
        };
        
        const { result: uploadResult } = yield call(uploadFileChunk, file, presignedUrl, onProgressCb, true);

        if (uploadResult) {
            alert("Uploaded Successfully!");
        }
        yield call(setLoading, false);
    }
    catch(e) {
        console.log(e);
        yield put(actions.getProductDLinkSuccess( "Failed to upload the file." ));
        yield call(setLoading(false));
    }
}

function* watchSaveAnimations(action) {
    const { data } = action;
    try {
        yield call(saveAnimationInstructions, data);
        alert("Success!");
        yield call(customHistory.push, "/orders");
        yield put(actions.clearInstructions());
    }
    catch(e) {
        if (e.status === 401) {
            alert("Please login.")
            yield call(customHistory.push, "/login");
        }
    }
}

function* watchLoadAnimations(action) {
    const { sku } = action;
    try {
        const response = yield call(getAnimationInstructions, sku);
        
        yield put(actions.loadAnimationSuccess(response.result));
    }
    catch(e) {
        if (e.status === 401) {
            alert("Please login.")
        }
        yield call(customHistory.push, "/login");
        console.log(e);
    }
}

export default function* rootSaga() {
    yield takeLatest(actionTypes.NEW_GREEN_SCREEN, watchNewGS);
    yield takeLatest(actionTypes.LOAD_FORM_ENTITIES, watchGetFormEntities);
    yield takeLatest(actionTypes.GET_PRODUCT_DL_LINK, watchGetPDL);
    yield takeLatest(actionTypes.UPDATE_PRODUCT_FILE, watchUpdateProductFile);
    yield takeLatest(actionTypes.SAVE_ANIMATION_INSTRUCTIONS, watchSaveAnimations);
    yield takeLatest(actionTypes.LOAD_ANIMATION_INSTRUCTIONS, watchLoadAnimations);
}
