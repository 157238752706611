import { 
    NEW_GREEN_SCREEN, 
    NEW_GREEN_SCREEN_SUCCESS, 
    LOAD_FORM_ENTITIES,
    LOAD_FORM_ENTITIES_SUCCESS,
    GET_PRODUCT_DL_LINK,
    GET_PRODUCT_DL_LINK_SUCCESS,
    UPDATE_PRODUCT_FILE,
    LOAD_ANIMATION_INSTRUCTIONS,
    LOAD_ANIMATION_INSTRUCTIONS_SUCCESS,
    SAVE_ANIMATION_INSTRUCTIONS,
    SAVE_ANIMATION_INSTRUCTIONS_SUCCESS,
    CLEAR_INSTRUCTIONS
} from './actionTypes';

export const newGreenScreenRequest = (data, setLoading) => {
    return {
        type: NEW_GREEN_SCREEN,
        data,
        setLoading
    }
}

export const newGreenScreenSuccess = (data) => {
    return {
        type: NEW_GREEN_SCREEN_SUCCESS,
        data,
    }
}

export const getFormEntitiesRequest = () => {
    return {
        type: LOAD_FORM_ENTITIES,
    }
}

export const getFormEntitiesRequestSuccess = (data) => {
    return {
        type: LOAD_FORM_ENTITIES_SUCCESS,
        data,
    }
}

export const getProductDLink = (sku, pwd, setLoading, callback) => {
    return {
        type: GET_PRODUCT_DL_LINK,
        sku,
        pwd,
        setLoading,
        callback,
    }
}

export const getProductDLinkSuccess = (data) => {
    return {
        type: GET_PRODUCT_DL_LINK_SUCCESS,
        data,
    }
}

export const updateProductFile = (sku, pwd, file, setProgress, setLoading) => {
    return {
        type: UPDATE_PRODUCT_FILE,
        sku,
        file,
        pwd,
        setProgress,
        setLoading
    }
}

export const loadAnimation = (SKU) => {
    return {
        type: LOAD_ANIMATION_INSTRUCTIONS,
        sku: SKU
    }
}

export const loadAnimationSuccess = (instructions) => {
    return {
        type: LOAD_ANIMATION_INSTRUCTIONS_SUCCESS,
        instructions
    }
}

export const saveAnimationInstructions = (data) => {
    return {
        type: SAVE_ANIMATION_INSTRUCTIONS,
        data
    }
}

export const saveAnimationInstructionsSuccess = (data) => {
    return {
        type: SAVE_ANIMATION_INSTRUCTIONS_SUCCESS,
        data
    }
}

export const clearInstructions = () => {
    return {
        type: CLEAR_INSTRUCTIONS,
    }
}

