import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/text-input';
import Button from '../../components/button';
import { setInput } from '../../utils';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    marginBottom: '10em'
  },
  media: {
    height: 140,
  },
  header: {
    marginBottom: '2em',
    '& p': {
      textAlign: "left",
      color: "gray"
    }
  }
});

export default function Login(props) {
  const classes = useStyles();

  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);

  const confirmEmail = (e) => {
      e.preventDefault();
        if (!code) {
            return;
        }

        props.confirmRequest(code, props.accountInfo);
        setLoading(true);
  }

  return (
      <div className="login">
        <div className={classes.card}>
            <div className={classes.header}>
              <h2>
                  Your account was created
              </h2>
              <p>
                  Please check your email, and enter the code to activate your account.
              </p>
            </div>
              <form onSubmit={confirmEmail} className={classes.container} noValidate autoComplete="off">
                  <TextField
                      label="Validation Code"
                      className={classes.textField}
                      placeholder="Code"
                      value={code}
                      onChange={setInput(setCode)}
                  />
                  <div className="form-actions">
                      <Button 
                        type="submit"
                        loading={loading}
                      >
                          Confirm
                      </Button>
                  </div>
              </form>
        </div>
    </div>
  );
}