import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { setInput } from '../../utils';

const possibleOptions = [ 
    "ignore", "Channel Url", "Display Name", "Username", "Sub Count", "Name", "Language", "Link", "From", "Message", "Picture", "Color", "Posts_1", "Posts_2", "Posts_3", "Subreddit", "Theme", "Social", "Handle", "Followers", "Following", "Posts"
 ];

 function FileUpload(props) {
    // State to store uploaded file
    const [file, setFile] = React.useState("");
  
    // Handles file upload event and updates state
    function handleUpload(event) {
      setFile(event.target.files[0]);
    }

    const uploadFile = () => {
        var xx = prompt("Are you sure you want to upload this file?", "Enter password to confirm");

        if (xx != null) {
            props.uploadFile(props.orderId, file, xx);
        }
    };
  
    return (
      <div id="upload-box" style={{ marginTop: "0.5em" }}>
        <input accept="application/zip" type="file" onChange={handleUpload} />
        {
            file && <div>
                <p>Filename: {file.name}</p>
                <Button onClick={uploadFile}> Upload File </Button>
                <Button onClick={() => setFile(undefined)}> Cancel</Button>
            </div>
        }
      </div>
    );
  }

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      padding: '1em',
      textAlign: 'left',
      maxWidth: 800,
      margin: "0 auto"
    },
    property: {
        color: "#555"
    },
    theBox: {
        background: '#eee',
        borderRadius: '0.5em',
        padding: '1em',
        marginBottom: '1.5em'
    },
    title: {
        margin: 0
    },
    lineItem: {
        marginTop: '1em'
    },
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    propertyRow: {
        display: "flex",
        justifyContent: "space-between"
    },
    alignRight: {
        textAlign: "right"
    },
    lineItem__row: {
        display: 'flex'
    }
  }));

let lineItemsProperties;

const OrderDetails = (props) => {

    console.log(props);

    const [order, setOrder] = useState(props.location.state.data || {});
    const [originalOrder] = useState(JSON.stringify(props.location.state.data));
    const [selectedIds, setSelectedIds] = useState([]);
    const [email, setEmail] = useState(order.email || "");
    useEffect(() => {
        console.log("here");
        // code to run on component mount
        setupLineItemsProperties();
    }, []);
    
    const selectId = (id) => () => {
        const index = selectedIds.indexOf(order.line_items[id].id);
        if (index === -1) {
            setSelectedIds([...selectedIds, order.line_items[id].id]);
        }
        else {
            const newArray = [...selectedIds];
            newArray.splice(index, 1);
            setSelectedIds(newArray);
        }
    };

    const setupLineItemsProperties = () => {
        lineItemsProperties = {};
        order.note_attributes && order.note_attributes.forEach( att => 
            {
                const ids = att.name.split("-");
               
                let valuesArray = lineItemsProperties[ids[0]];
                
                if (!ids[1]) {
                   return;
                }

                console.log(ids);

                //update order
                const line_item = order.line_items.find(item => item.id == ids[0]);

                if (!line_item) {
                    return;
                }

                const property = line_item.properties && line_item.properties.find(prop => prop.name === ids[1]);
                if (property) {
                    property.value = att.value;
                }
                else {
                    if (!line_item.properties) 
                    {
                        line_item.properties = []
                    }
                    line_item.properties.push({ name: ids[1], value: att.value });
                }


                if (valuesArray) {
                    valuesArray.push({ name: ids[1], value: att.value });
                }
                else {
                    lineItemsProperties[ids[0]] = [ { name: ids[1], value: att.value } ];
                }
            }
        );
        console.log(lineItemsProperties);
        const newOrder = {...order};
        setOrder(newOrder);
    };

    const classes = useStyles();

    const [editCustomer, setEditCustomer] = useState(false);
    const [editItem, setEditItem] = useState(false);

    const updatePropName = (itemIndex, propIndex) => (e) => {
        
        console.log(e.target.value);

        const orderCopy = { ...order };

        const line_item = orderCopy.line_items[itemIndex];
        line_item.properties[propIndex].name = e.target.value;
        
        setOrder(orderCopy);

        console.log(props.location.state.data);
    };

    const renderSelected = () => {

        props.sendRerenderRequest( selectedIds , order.id );

    };

    const cancelChanges = () => {
        const ogOrder = JSON.parse(originalOrder);
        setOrder(ogOrder);
        setEditItem(false);

        //RESET BTS
        setupLineItemsProperties();
    };

    const updatePropValue = (itemIndex, propIndex) => (e) => {
       
        const orderCopy = { ...order };
        
        const line_item = orderCopy.line_items[itemIndex];
        line_item.properties[propIndex].value = e.target.value;
        
        //updated line item props BTS
        const lineItemProps = lineItemsProperties[line_item.id];

        if (lineItemProps) {
            console.log("found");
            //Set value.
            const list = lineItemProps;
            let line_item_property = list.find(val => val.name === line_item.properties[propIndex].name);

            if (line_item_property) {
                line_item_property.value = e.target.value;
            }
            else {
                line_item_property = { name: line_item.properties[propIndex].name, value: e.target.value };
                list.push(line_item_property);
            }
        }
        else {
            console.log("New Change");
            lineItemsProperties[line_item.id] = {};
            lineItemsProperties[line_item.id] = [ { name: line_item.properties[propIndex].name, value: e.target.value } ];
            console.log(lineItemsProperties);
        }

        setOrder(orderCopy);
    };

    const addProp = (itemIndex) => (e) => {

        const orderCopy = { ...order };

        const line_item = orderCopy.line_items[itemIndex];

        if (!line_item.properties) {
            line_item.properties = [];
        }

        if (line_item.properties[line_item.properties.length - 1] && line_item.properties[line_item.properties.length - 1].name === "") {
            alert("Duplicate empty property...");
            return;
        }

        line_item.properties.push({ name: "", value: "" });
        
        setOrder(orderCopy);
    };

    const saveProperties = (e) => {
        props.saveProperties(order.id, lineItemsProperties);
        setEditItem(false);
    };

    const getDownloadLink = () => {
        props.getDownloadLink(order.id);
    };

    const getRenderMessage = () => {
        props.getRenderMessage(order.id);
    };

    const sendToRender = () => {
        const result = window.confirm("Resend to queue?");
        if (result) {
            props.sendToRender(order.id);
        }
    };

    const sendUpdateEmail = () => {
        const result = window.confirm("Send Updated Email?");
        if (result) {
            props.sendUpdatedEmail(order.id);
        }
    };

    const createWebmFiles = () => {
        const result = window.confirm("Add Webm Files To Order?");
        if (result) {
            props.createWebmRequest(order.id);
        }
    };

    const saveEmail = () => {
        setEditCustomer(false);
        setOrder({ ...order, email });
        props.saveCustomerEmail(order.id, email);
    };

    const downloadProduct = (orderItemId) => () => {
        props.getSingleDownloadLink(order.id, orderItemId);
    }

    return <div className={classes.root}>
        <h3>Order #{ order.order_number } - { order.fulfillment_status } </h3> 
        <p> { order.created_at } </p>

        <div className={classes.theBox}>
            <div className={classes.header}>
                <h4 className={classes.title}> Customer </h4> 
                {
                    !editCustomer && <Button onClick={() => { setEditCustomer(!editCustomer) } }>Edit</Button>
                }
                {
                    editCustomer && <Button onClick={() => { setEditCustomer(!editCustomer) } }>Cancel</Button>
                }
            </div>
            <div>
                <p> { order.customer.first_name } { " " } { order.customer.last_name }
                    <br/>
                    {
                        editCustomer && <div>
                            <input type="text" value={email} onChange={setInput(setEmail)} />
                            <Button onClick={saveEmail}>Save</Button>
                        </div>
                    }
                    
                    {
                        !editCustomer && <p>{ order.email }</p>
                    }
                </p>
            </div>
        </div>

        <div className={classes.theBox}>
            <div className={classes.header}>
                <h4 className={classes.title}> Order Items </h4> 
                {
                    !editItem && <Button onClick={() => { setEditItem(!editItem) } }>Edit</Button>
                }
                {
                    editItem && <Button onClick={cancelChanges}>Cancel</Button>
                }
            </div>
            <div>
                {
                    order.line_items.map((item, index) => ( 
                        
                        <div className={classes.lineItem}>
                            <b>{ item.name }</b>
                            <div className={classes.lineItem__row}>
                                <div className={classes.lineItem__prop}>
                                    {
                                    !editItem &&
                                        item.properties.map( prop => (
                                            <div className={classes.propertyRow}>
                                                <div className={classes.property}>
                                                    { prop.name } { ": " } { prop.value } 
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                {  
                                    !editItem &&
                                    <div>
                                        { item.sku.indexOf("CST-") == -1 && <input onClick={selectId(index)} type="checkbox" /> }
                                       { !editItem && item.sku.indexOf("CST-") != -1 && <Button color="primary" variant="outlined" size="small" style={{marginTop: '4px'}} onClick={downloadProduct(item.id)}> Get Link </Button> }
                                    </div>
                                }
                            </div>
                            {
                               editItem &&
                                item.properties.map( (prop, propIndex) => (
                                <div className={classes.property}>
                                    <select onChange={updatePropName(index, propIndex)} value={prop.name}> 
                                        { 
                                            possibleOptions.map(opt => <option value={opt}> { opt } </option>)  
                                        }
                                    </select> { ": " } 
                                    <input type="text" value={prop.value} onChange={updatePropValue(index, propIndex)} />
                                </div>))
                            }
                            {
                                editItem &&
                                <Button onClick={addProp(index)}>+</Button>
                            }
                        </div>
                    ))
                }
                <div className={classes.alignRight}>
                    {
                        editItem &&
                        <Button onClick={saveProperties}>Save Changes</Button>
                    }
                </div>
                <div className={classes.alignRight}>
                    {
                        !editItem && selectedIds.length > 0 &&
                        <Button variant="outlined" onClick={renderSelected}> Rerender Selected ( { selectedIds.length } ) </Button>
                    }
                </div>
            </div>
        </div>

        <div className={classes.theBox}>
            <h4 className={classes.title}> Options </h4>
            <Button color="primary" style={{marginTop: "10px", marginRight: "5px"}} variant="outlined" onClick={getDownloadLink}> Get Download Link </Button> 

            <Button color="primary" style={{marginTop: "10px", marginRight: "5px"}} variant="outlined" onClick={getRenderMessage}> Get Render Message </Button> 
            {
                order && order.fulfillment_status !== "fulfilled" && <Button onClick={sendToRender}> Send To Render Queue </Button>
            }
            <Button color="secondary" style={{marginTop: "10px", marginRight: "5px"}} variant="outlined" onClick={sendUpdateEmail}> Send Update Email </Button>
            <Button color="secondary" style={{marginTop: "10px" }} variant="outlined" onClick={createWebmFiles}> Create Webm Files </Button>
        </div>

        <div className={classes.theBox}>
            <h4 className={classes.title}> Update File </h4>
            {
                order &&
                <FileUpload 
                    orderId={order.id}
                    uploadFile={props.uploadFile}
                />
            }
        </div>

        <textarea style={{ width: "100%", height: "100px" }} id="download-url" type="text" readOnly />
    </div>
}

export default OrderDetails;