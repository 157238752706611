import React, { useState } from 'react';
import './register.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DefaultInput from '../../components/text-input';
import Button from '../../components/button';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
      width: '100%'
  },
  titleContainer: {
    marginBottom: '2em'
  },
  container: {
      maxWidth: 345,
      width: '100%',
      marginBottom: '3em'
  },
  actions: {
      marginTop: '2em'
  },
});

export default function Register(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [name] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const setInput = (fn) => (event) => {
        fn(event.target.value);
  };

  const submit = (event) => {
        event.preventDefault();
        if (!email || !password) {
            alert("All fields are required.");
        }
        else if (!password) {
            alert("password should be atleast 8 characters.");
        }
        else if (props.location.state && props.location.state.code) {
            setLoading(true);
            props.registerRequest(email, name, password, props.location.state.code);
        } else {
            alert("Please scan your card again.");
        }
  };
  return (
      <div className="register">
        <div className={classes.container}>
        <div className={classes.titleContainer}>
            <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                Get Started
            </Typography>
            <p>
                Let's create your account.
            </p>
        </div>
            <form className={classes.container} noValidate autoComplete="off" onSubmit={submit}>
                <DefaultInput
                    label="Email"
                    className={classes.textField}
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    variant="filled"
                    fullWidth
                    value={email}
                    onChange={setInput(setEmail)}
                />
                <DefaultInput
                    id="outlined-password-input"
                    label="Password"
                    className={classes.textField}
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    variant="filled"
                    fullWidth
                    value={password}
                    onChange={setInput(setPassword)}
                />
                <div className={classes.actions}>
                    <Button loading={loading} type="submit" size="small" color="primary">
                        Register
                    </Button>
                </div>
            </form>
        </div>
    </div>
  );
}