import { URLS, getAccessToken } from "../../utils";

export async function createTicket({product_id, message, title}) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      product_id,
      message,
      title
    })
  };
  const url = URLS.PUBLIC_API + "/chat/create-ticket";
 console.log('api.js create ticket') 
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}


  export async function getAllActiveRooms() {
    const req = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
    const url = URLS.PUBLIC_API + "/chat/rooms";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export const handleResponse = async (request, response) => {
    let result;

    try {
      result = await response.json();
    } catch (err) {
     //response is empty but 200...
      if (response.ok) {
        return {
          result: "OK",
        }
      }
      throw Object.assign(new Error("Unhandled Error"));
    };

    if (response.status === 401) {
      alert('Please login again...')
      window.location.href = '/' 
      return null;
    }

    if (!response.ok) {
      const genericMessage = `HTTP ${response.status} (${response.statusText})`;
      
      return Object.assign(new Error(genericMessage), {
        error: result,
        status: response.status
      });
    }

    return {
      response, 
      result 
    };
};