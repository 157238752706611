import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import createBrowserHistory from 'history/createBrowserHistory';
import AppBar from '../appBar';
import Login from '../../pages/login';
import Register from '../../pages/register';
import Home from '../../pages/home';
import { ROUTES } from "./routes";
import confirm from "../../pages/confirm";
import ResetPass from "../../pages/resetPassword";
import AdminSettings from '../../pages/admin-settings';
import RegisterForm from '../../pages/register-form';
import OrderDetails from "../../pages/order-details";
import NewProduct from "../../pages/newProduct";
import NewProfile from "../../pages/new-profile";
import Profiles from "../../pages/profiles";
import ProfilesNew from "../../pages/profiles-new";
import ProfileDetail from "../../pages/profile-detail";
import ProfileNewDetail from "../../pages/profile-detail-new";
import editProfile from "../../pages/edit-profile";
import editProfileNew from "../../pages/edit-profile-new";
import products from "../../pages/products";
import productReview from "../../pages/product-review";
import instructions from "../../pages/instructions";
import ProductReviewDetails from "../../pages/product-review/product-review-details";
// import SupportChat from "../../pages/support-chat";

export const customHistory = createBrowserHistory();

function AppRouter() {
  return (
    <Router history={customHistory}>
        <AppBar />
        <Switch>
          
          <Route path="/" exact component={Login} />
          {/* <Route path={ROUTES.SUPPORT_CHAT} component={SupportChat} /> */}
          <Route path={ROUTES.PRODUCT_REVIEW} component={productReview} />
          <Route path={ROUTES.PRODUCT_REVIEW_DETAIL} component={ProductReviewDetails} />
          <Route path={ROUTES.PROFILES} component={Profiles} />
          <Route path={ROUTES.PROFILES_NEW} component={ProfilesNew} />
          <Route path={ROUTES.PROFILE_OLD} component={ProfileDetail} />
          <Route path={ROUTES.PROFILE_NEW} component={ProfileNewDetail} />
          <Route path={ROUTES.EDITPROFILE} component={editProfile} />
          <Route path={ROUTES.EDITPROFILE_NEW} component={editProfileNew} />
          <Route path={ROUTES.NEWPROFILE} component={NewProfile} />
          <Route path={ROUTES.NEWGS} component={NewProduct} />
          <Route path={ROUTES.LOGIN} exact component={Login} />
          <Route path={ROUTES.ORDER} component={OrderDetails} />
          <Route path={ROUTES.HOME} component={Home} />
          <Route path={ROUTES.REGISTER} component={Register} />
          <Route path={ROUTES.CONFIRM} component={confirm} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPass} />
          <Route path={ROUTES.ADMIN_SETTINGS} component={AdminSettings} />
          <Route path={ROUTES.NEW_ACCOUNT} component={RegisterForm} />
          <Route path={ROUTES.PRODUCTS} component={products} />
          <Route path={ROUTES.INSTRUCTIONS} component={instructions} />
          <Route path={ROUTES.PROFILE} component={Home} />

        </Switch>
    </Router>
  );
}

export default AppRouter;