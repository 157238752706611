export const GET_ALL_REVIEWABLE_PRODUCTS= "GET_ALL_REVIEWABLE_PRODUCTS";
export const GET_ALL_REVIEWABLE_PRODUCTS_SUCCESS= "GET_ALL_REVIEWABLE_PRODUCTS_SUCCESS";

export const APPROVE_PRODUCT = "APPROVE_PRODUCT";
export const APPROVE_PRODUCT_SUCCESS= "APPROVE_PRODUCT_SUCCESS";

export const REJECT_PRODUCT = "REJECT_PRODUCT";
export const REJECT_PRODUCT_SUCCESS = "REJECT_PRODUCT_SUCCESS";

export const DISMISS_MESSAGES = "DISMISS_MESSAGES";
