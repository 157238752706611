import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import DefaultButton from '../../components/button';
import Button from '../../components/button';
import { ROUTES } from '../../components/router/routes';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
    width: '100%'
  },
  titleContainer: {
    marginBottom: '2em'
  },
  container: {
    width: '100%',
    marginBottom: '2em'
  },
  profileBox: {
    background: '#eee',
    padding: '5px',
    maxWidth: 500,
    margin: '0 auto',
    marginTop: '1em'
  },
  pic: {
    height: 150,
    width: 150,
    borderRadius: '50%',
    border: '3px solid #ccc'
  },
  assignBtn: {
    marginTop: '1em'
  },
  options: {
    display: 'flex',
    flexDirection: 'column'
  },
  menu: {
    marginTop: '0.5em',
    marginBottom: '3em'
  },
  page: {
    maxWidth: 600,
    margin: "0 auto",
    padding: "2em"
  },
  socialMedia: {
    textAlign: "left"
  }
});

export default function Profiles(props) {
  const classes = useStyles();
  const profile = props.location.state && props.location.state.data

  return (
    <div className={classes.page}>

      <div className={classes.menu}>
        <Link  
          to={{
            pathname: ROUTES.PROFILES_NEW,
          }}
        >
          <Button> View All Profiles </Button>
        </Link>
      </div>

      <div className={classes.container}>
        <img className={classes.pic} src={profile.picture_url || "https://d3c4b0rnzc49yh.cloudfront.net/p/3dc04545-37f0-41ce-b16e-78186c475ded.jpeg"} />
        <p>{profile.displayName} {profile.verified ? <img style={{ height: 16 }} src="https://cdn.shopify.com/s/files/1/0273/8080/9781/files/verified-creatorset.png?v=1647750139" /> : ""}</p>
        <p> { profile.bio } </p>

        <div className={classes.socialMedia}>
          <b>
            Social Media
          </b>

          <ul>
            { profile.socialMedia && profile.socialMedia.map( i => <li> <b> { i.platform } </b> : { i.url } </li> ) }
          </ul>
        </div>
      </div>
      <div className={classes.options}>
        <Link  
          to={{
            pathname: ROUTES.EDITPROFILE_NEW,
            state: { data: profile }
          }}
        >
          <Button> Edit Profile </Button>
        </Link>
        <a target='_blank' href={`https://creatorset.com/pages/workshop/${profile.url}`}>
          <Button> View Public Profile /{ profile.url }</Button>
        </a>
      </div>
    </div>
  );
}