import {
  CREATE_TICKET,
  CREATE_TICKET_SUCCESS,
  DISMISS_MESSAGES,
} from "./actionTypes";

export const createTicket = ({ product_id, message, title}) => {
  return {
    type: CREATE_TICKET,
    product_id,
    message,
    title
  };
};

export const createTicketSuccess = (message) => {
  return {
    type: CREATE_TICKET_SUCCESS,
    message,
  };
};

export const dismissMessages = () => {
  return {
    type: DISMISS_MESSAGES,
  };
};
