import OrderDetais from './orderDetails';
import { connect } from 'react-redux';
import {
    saveProperties,
    getDownloadLink,
    sendUpdatedEmail,
    sendRerenderRequest,
    getRenderMessage,
    sendToRender,
    saveCustomerEmail,
    uploadFile,
    createWebmRequest,
    getSingleDownloadLink
} from '../../services/profileService/actions';

export default connect(state => ({ orders: state.profileReducer.orders }), {
    saveProperties,
    getRenderMessage,
    getDownloadLink,
    sendUpdatedEmail,
    createWebmRequest,
    sendRerenderRequest,
    sendToRender,
    saveCustomerEmail,
    uploadFile,
    getSingleDownloadLink
})(OrderDetais);