import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DefaultInput from '../../components/text-input';
import { setInput } from '../../utils';
import DefaultButton from '../../components/button';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  form: {
    padding: '1em',
    maxWidth: 800,
    margin: "0 auto",
    width: '100%'
  },
  container: {
    width: 500,
    margin: '0 auto'
  },
  imgPlc: {
    width: '200px',
    background: "#e1e1e1",
    margin: '0 auto',
    marginBottom: "1em",
  },
  card: {
    marginBottom: '3em'
  },
  button: {
    marginTop: '1em'
  }
});

function FileUpload(props) {
    // Handles file upload event and updates state
    function handleUpload(event) {
      props.setFile(event.target.files[0]);
      if (props.requiresPreview) {
        props.setPreview(URL.createObjectURL(event.target.files[0]));
      }
    }

    const classes = useStyles();

    const { file, preview } = props;
    return (
      <div id="upload-box" className={props.className}>
        {
            !file && <div style={{ height: 200 }} className={classes.imgPlc}>

            </div>
        }
        {
            file && <div>
                <Button size="small" onClick={() => { props.setFile(undefined); props.requiresPreview && props.setPreview(undefined); }}> Clear Selection </Button>
            </div>
        }
        {
            file && props.requiresPreview && file.type.indexOf("image") !== -1 && <div>
               <img alt="image_preview" style={{maxWidth: '200px', marginBottom: '0.5em'}} src={ preview } />
            </div>
        }
        {
            file && props.requiresPreview && file.type.indexOf("video") !== -1 && <div>
                <video controls style={{maxWidth: '200px', marginBottom: '0.5em'}} src={ preview } />
            </div>
        }
        {
            file && !props.requiresPreview && <p> {file.name} </p>
        }

        <input accept={props.accept} type="file" onChange={handleUpload} />
      </div>
    );
  }

export default function NewProfileForm(props) {

  const classes = useStyles();

  const [thumbnail, setThumbnail] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [channelName, setChannelName] = React.useState("");
  const [displayName, setDisplayName] = useState("");
  const [twitter, setTwitter] = useState("");
  const [twitch, setTwitch] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [reddit, setReddit] = useState("");
  const [facebook, setFacebook] = useState("");
  const [website, setWebsite] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [discord, setDiscord] = useState("");
  const [spotify, setSpotify] = useState("");

  const [thumbPreview, setPreviewThumb] = React.useState("");

  const submit = (e) => {

    e.preventDefault(); 

    const data = {
        displayName,
        url,
        thumbnail,
        bio,
        channelName,
        twitter,
        youtubeUrl,
        instagram,
        tiktok,
        twitch,
        reddit,
        website,
        facebook,
        discord
    }

    if (!data.displayName || !thumbnail || !url) {
        alert("You need to fill all required fields.");
        return;
    }
    
    props.newProfile(
        data,
    )
  };

  return (

      <div className={classes.form}>
        <div className={classes.card}>
            <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                New Profile <span aria-label="img" role="img">💼</span>
            </Typography>
            <form onSubmit={submit} className={classes.container} noValidate autoComplete="off">

                <div className={classes.uploadInput}>
                    <p>Profile Picture</p>
                    <FileUpload 
                        className={classes.uploadBox}
                        accept="image/png, image/gif, image/jpeg"
                        file={thumbnail}
                        requiresPreview
                        preview={thumbPreview}
                        setPreview={setPreviewThumb}
                        setFile={setThumbnail}
                    />
                </div>

                <DefaultInput 
                    className={classes.textField}
                    label="Display Name"
                    type="title"
                    name="display_name"
                    placeholder="Name as it should be displayed"
                    value={displayName}
                    onChange={setInput(setDisplayName)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Bio"
                    type="text"
                    name="bio"
                    value={bio}
                    placeholder="Optional"
                    onChange={setInput(setBio)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Profile Url"
                    type="text"
                    name="url_path"
                    placeholder="endpoint"
                    value={url}
                    pre={'https://creatorset.com/pages/workshop/'}
                    onChange={setInput(setUrl, true)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Twitter"
                    type="text"
                    name="twitter"
                    placeholder="Optional. Enter value without @"
                    value={twitter}
                    onChange={setInput(setTwitter)}
                />
                <DefaultInput 
                    className={classes.textField}
                    label="Youtube Url"
                    type="text"
                    placeholder="Optional. Enter full channel url"
                    name="youtube_url"
                    value={youtubeUrl}
                    onChange={setInput(setYoutubeUrl)}
                />
                <DefaultInput 
                    className={classes.textField}
                    label="Channel Name"
                    type="text"
                    placeholder="Optional. Enter channel name"
                    name="youtube_channel_name"
                    value={channelName}
                    onChange={setInput(setChannelName)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Instagram"
                    type="text"
                    name="instagram"
                    placeholder="Optional. Enter value without @"
                    value={instagram}
                    onChange={setInput(setInstagram)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="TikTok"
                    type="text"
                    name="tiktok"
                    placeholder="Optional. Enter value without @"
                    value={tiktok}
                    onChange={setInput(setTiktok)}
                />
                
                <DefaultInput 
                    className={classes.textField}
                    label="Website"
                    type="text"
                    name="website"
                    placeholder="Optional. Enter value without @"
                    value={website}
                    onChange={setInput(setWebsite)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Reddit"
                    type="text"
                    name="reddit"
                    placeholder="Optional. Enter value without @"
                    value={reddit}
                    onChange={setInput(setReddit)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Twitch"
                    type="text"
                    name="twitch"
                    placeholder="Optional. Enter value without @"
                    value={twitch}
                    onChange={setInput(setTwitch)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Facebook"
                    type="text"
                    name="facebook"
                    placeholder="Optional. Enter value without @"
                    value={facebook}
                    onChange={setInput(setFacebook)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Discord"
                    type="text"
                    name="discord"
                    placeholder="Optional. Enter discord url."
                    value={discord}
                    onChange={setInput(setDiscord)}
                />

                <DefaultInput 
                    className={classes.textField}
                    label="Spotify"
                    type="text"
                    name="spotify"
                    placeholder="Optional. Spotify url"
                    value={spotify}
                    onChange={setInput(setSpotify)}
                />

                <DefaultButton className={`default-button ${classes.button}`} type="submit">
                    Create Profile
                </DefaultButton>
            </form>
        </div>
</div>
  );
}