import { takeLatest, call, put, take } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
    getAllReviewableProducts,
    approveProduct,
    rejectProduct
} from './api';
import { approveProductSuccess, getAllReviewableProductsSuccess} from './actions';

function* watchGetReviewableProducts(action) {
    try {
        const r = yield call(getAllReviewableProducts);

        if (r) {
            console.log(r);
            yield put(getAllReviewableProductsSuccess(r.result));
        }
    }
    catch (e) {
        console.log(e);
        alert("An error occured. Make sure email is valid.");
    }
}


function* watchApproveProduct(action) {
    try {
        const r = yield call(approveProduct, {product_id: action.product_id});

        if (r) {
            console.log(r);
            yield put(approveProductSuccess(r.result));
        }
    }
    catch (e) {
        console.log(e);
        alert("An error occured. Make sure email is valid.");
    }
}

function* watchRejectProduct(action) {
    try {
        const {product_id, reason} = action;
        const r = yield call(rejectProduct, {product_id, reason});

        if (r) {
            console.log(r);
            yield put(approveProductSuccess(r.result));
        }
    }
    catch (e) {
        console.log(e);
        alert("An error occured. Make sure email is valid.");
    }
}


export default function* rootSaga() {
    yield takeLatest(actionTypes.GET_ALL_REVIEWABLE_PRODUCTS, watchGetReviewableProducts);
    yield takeLatest(actionTypes.APPROVE_PRODUCT, watchApproveProduct);
    yield takeLatest(actionTypes.REJECT_PRODUCT, watchRejectProduct);
}
