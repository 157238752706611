export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const CONFIRM_REQUEST = "CONFIRM_REQUEST";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";

export const GET_CLIENTS_RESIDENTIELS = "GET_CLIENTS_RESIDENTIELS";
export const GET_CLIENTS_RESIDENTIELS_SUCCESS = "GET_CLIENTS_RESIDENTIELS_SUCCESS";

export const GET_CLIENTS_AFFAIRES = "GET_CLIENTS_AFFAIRES";
export const GET_CLIENTS_AFFAIRES_SUCCESS = "GET_CLIENTS_AFFAIRES_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const REAUTH = "REAUTH";
export const REAUTH_SUCCESS = "REAUTH_SUCCESS";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const UPDATE_LOGIN_ATTEMPTS = "UPDATE_LOGIN_ATTEMPTS";

export const GET_MAX_LOGIN_ATTEMPTS = "GET_MAX_LOGIN_ATTEMPTS";
export const GET_MAX_LOGIN_ATTEMPTS_SUCCESS = "GET_MAX_LOGIN_ATTEMPTS_SUCCESS";

export const LOGOUT = "LOGOUT";

export const GET_MY_INFO = "GET_MY_INFO";
export const GET_MY_INFO_SUCCESS = "GET_MY_INFO_SUCCESS";


