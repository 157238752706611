import React, { useState } from 'react';
import './login.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DefaultInput from '../../components/text-input';
import { setInput } from '../../utils';
import DefaultButton from '../../components/button';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    width: '100%',
    marginBottom: '6em'
  },
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
    marginBottom: "3em",
  },
  formActions: {
    marginTop: "1.5em"
  }
});

export default function Login(props) {
  const classes = useStyles();

  const { loginRequest } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const login = (e) => {
    e.preventDefault();
    loginRequest(email, password);
    setLoading(true);
  }

  const forgotPassword = () => {
    const email = prompt("Please enter your email, and we'll send you a code.");
    if (email) {
      props.forgotPassword(email);
    }
  };

  return (
      <div className="login">
        <div className={classes.card}>
            <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                Welcome Agent 007
            </Typography>
            <form onSubmit={login} className={classes.container} noValidate autoComplete="off">
                <DefaultInput 
                    className={classes.textField}
                    label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={setInput(setEmail)}
                />
                <DefaultInput 
                    label="Password"
                    className={classes.textField}
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={setInput(setPassword)}
                />
                <div className={classes.formActions}>
                    <DefaultButton 
                      loading={loading}
                      type="submit"
                      className="default-button">
                        Login
                    </DefaultButton>
                    <a className="button-link" onClick={forgotPassword}>
                      Forgot your password?
                    </a>
                </div>
            </form>
        </div>
</div>
  );
}