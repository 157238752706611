import { all, fork  } from 'redux-saga/effects';
import AuthSaga from '../../services/authService';
import ProfileSaga from '../../services/profileService';
import ProductSaga from '../../services/productService';
import ProductReviewsSaga from '../../services/productReviewsService';
import ChatSaga from '../../services/chatService';

export default function* root() {
    yield all([AuthSaga, ProfileSaga, ProductSaga, ProductReviewsSaga, ChatSaga].map(saga => fork(saga)));
}
