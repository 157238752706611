import React from 'react';
import './register.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '../../components/button';
import Link from '../../components/link';
import { ROUTES } from '../../components/router/routes';
import CardIcon from '../../components/icons/CardIcon';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
      width: '100%'
  },
  titleContainer: {
      marginTop: '-3em',
      marginBottom: '2em'
  },
  card: {
      marginTop: '-4em'
  },
  container: {
      maxWidth: 345,
  }
});

export default function Register(props) {
  const history = useHistory();
  const classes = useStyles();
console.log(props);
  const goToAccountCreation = () => {
    history.push(ROUTES.NEW_ACCOUNT, { code: props.location.state.code });
  };
  const redirectToLoginWithNewProfile = () => {
    history.push(ROUTES.LOGIN);
  };

  return (
      <div className="register">
        <div className={classes.container}>
        <CardIcon className={classes.card} />
        <div className={classes.titleContainer}>
            <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                Setup your card
            </Typography>
            <p>
                Let's assign your card to your account.
            </p>
        </div>
        <div className={classes.buttons}> 
            <Button 
                type="submit" 
                size="small" 
                onClick={goToAccountCreation}>
                New Account
            </Button>
            <Link
                onClick={redirectToLoginWithNewProfile}
                text="Existing Account">
            </Link>
        </div>
        
        </div>
    </div>
  );
}