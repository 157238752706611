import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import DefaultButton from '../../components/button';
import Button from '../../components/button';
import { ROUTES } from '../../components/router/routes';
import { Link } from 'react-router-dom';
const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
    width: '100%'
  },
  titleContainer: {
    marginBottom: '2em'
  },
  container: {
    width: '100%',
    marginBottom: '2em'
  },
  profileBox: {
    background: '#eee',
    padding: '5px',
    maxWidth: 500,
    margin: '0 auto',
    marginTop: '1em'
  },
  pic: {
    maxHeight: 150,
    // borderRadius: '50%',
    border: '3px solid #ccc'
  },
  assignBtn: {
    marginTop: '1em'
  },
  options: {
    display: 'flex',
    flexDirection: 'column'
  },
  menu: {
    marginTop: '0.5em',
    marginBottom: '3em'
  }
});

export default function ProductReviewDetails(props) {
  const classes = useStyles();
  const product = props.location.state && props.location.state.data

  return (
    <div className="profiles">

      <div className={classes.menu}>
        <Link  
          to={{
            pathname: ROUTES.PRODUCT_REVIEW,
          }}
        >
          <Button> View All Ready For Review </Button>
        </Link>
      </div>

      <div className={classes.container}>
        <img className={classes.pic} src={product.previewImages[0].filepath} />
        <p>{product.display_name}</p>
      </div>
      <div className={classes.options}>
        <a target='_blank' href={product.onlineStoreUrl}>
          <Button> Preview in Shopify Shop </Button>
        </a>
      </div>
    </div>
  );
}