import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { TextField, ButtonBase } from '@material-ui/core';
import { setInput } from '../../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledExpansionPanels(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [attempts, setAttempts] = React.useState(props.maxAttempts);

  const save = () => {
    if (attempts && attempts > 0 && props.maxAttempts !== attempts) {
      props.updateLoginAttemptsCount(attempts);
    }
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Role</Typography>
          <Typography className={classes.secondaryHeading}>
            Admin Account
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            You have administrator rights.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Missed Attempts</Typography>
          <Typography className={classes.secondaryHeading}>
            Block Accounts After # Attempts
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TextField
              id="outlined-email-input"
              label="Number of attempts"
              className={classes.textField}
              type="number"
              name="number"
              margin="normal"
              variant="filled"
              fullWidth
              value={attempts}
              onChange={setInput(setAttempts)}
          />
          <ButtonBase onClick={save}>Save</ButtonBase>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}