import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ROUTES } from '../../components/router/routes';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: '1em',
    maxWidth: 500,
    margin: "0 auto"
  },
  button: {
    marginBottom: '1em'
  },
  actions: {
      textAlign: "right"
  },
  order: {
    width: 500,
    background: '#eaeaea',
    margin: '0 auto',
    borderRadius: 3,
    padding: '1em',
    marginBottom: '1em',
    maxWidth: '100%'
  }
}));

const Home = (props) => {

  const classes = useStyles();
    useEffect(() => {
      //TODO: get unfulfilled orders
    }, []);

    console.log(props);

    return (
      <div className={classes.root}>

        {
          !props.orders &&
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h1>CreatorSet Support <span aria-label="phone" role="img">📞</span></h1>

            <Link  to={{
              pathname: ROUTES.NEWGS
            }}>
              <Button className="default-button"> Add new product/gs </Button>
             </Link>

             <Link  to={{
              pathname: ROUTES.NEWPROFILE
            }}>
              <Button style={{marginTop: '0.5em'}} className="default-button"> Create new profile </Button>
             </Link>


             <Link  to={{
              pathname: ROUTES.PROFILES
            }}>
              <Button style={{marginTop: '0.5em'}} className="default-button"> View Profiles (Old) </Button>
             </Link>

             <Link  to={{
              pathname: ROUTES.PROFILES_NEW
            }}>
              <Button style={{marginTop: '0.5em'}} className="default-button"> View Profiles </Button>
             </Link>

             <Link to={{
              pathname: ROUTES.PRODUCTS
            }}>
              <Button style={{marginTop: '0.5em'}} className="default-button"> Test Products </Button>
             </Link>

             <Link to={{
              pathname: ROUTES.INSTRUCTIONS
            }}>
              <Button style={{marginTop: '0.5em'}} className="default-button"> Animations Instructions </Button>
             </Link>

             <Link to={{
              pathname: ROUTES.PRODUCT_REVIEW
            }}>
              <Button style={{marginTop: '0.5em'}} className="default-button"> View Products For Review </Button>
             </Link>

          </div>
        }
          { 
           props.orders && props.orders.map((order) => (
             <Link  to={{
              pathname: "/orders/" + order.id,
              state: { data: order }
            }}>
               <div className={classes.order}>
                <div>
                  #{ order.order_number } - { order.id }
                </div>
                <div>
                { order.customer.first_name } { order.customer.last_name }
                </div>
                <div>
                  { order.customer.email }
                </div>

              </div>
             </Link>))
          }
      </div>
    );
}

export default Home;


