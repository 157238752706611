export const handleResponse = async (request, response) => {
    let result;

    try {
      result = await response.json();
    } catch (err) {
     //response is empty but 200...
      if (response.ok) {
        return {
          result: "OK",
        }
      }
      throw Object.assign(new Error("Unhandled Error"));
    };

    if (!response.ok) {
      const genericMessage = `HTTP ${response.status} (${response.statusText})`;
      throw Object.assign(new Error(genericMessage), {
        error: result,
        status: response.status
      });
    }

    return {
      response, 
      result 
    };
};

export const CDN_URL = "https://d1w2uza1cucogo.cloudfront.net";
export const DEFAULT_PROFILE = CDN_URL + "/p/profile.png";
export const DEFAULT_BANNER = CDN_URL + "/p/banner.jpg";

export const LINKS_MAP = {
  "IG": {
    "displayName": "Instagram",
    "description": "Enter your Instagram handle",
    "type": "text",
    "url": "https://www.instagram.com/",
    "action": "open",
    "logo": "instagram"
  },
  "TW": {
    "displayName": "Twitter",
    "description": "Enter your Twitter handle",
    "type": "text",
    "url": "https://www.twitter.com/",
    "action": "open",
    "logo": "twitter"
  },
  "TK": {
    "displayName": "TikTok",
    "description": "Enter your Tiktok username",
    "type": "text",
    "url": "https://www.tiktok.com/@",
    "action": "open",
    "logo": "tiktok"
  },
  "SC": {
    "displayName": "Snapchat",
    "description": "Enter your Snapchat username",
    "type": "text",
    "url": "https://www.snapchat.com/add/",
    "action": "open",
    "logo": "snapchat"
  },
  "EM": {
    "displayName": "Email",
    "description": "Enter your Email",
    "type": "email",
    "action": "copy",
    "logo": "aroba"
  },
  "YT": {
    "displayName": "YouTube",
    "description": "Enter your Youtube Channel Url",
    "type": "url",
    "action": "open",
    "logo": "youtube"
  },
  "SD": {
    "displayName": "SoundCloud",
    "url": "https://soundcloud.com/",
    "description": "Enter your SoundCloud username",
    "type": "text",
    "action": "open",
    "logo": "soundcloud"
  },
  "OF": {
    "displayName": "OnlyFans",
    "url": "https://onlyfans.com/",
    "description": "Enter your OnlyFans username",
    "type": "text",
    "action": "open",
    "logo": "onlyfans"
  },
  "BW": {
    "displayName": "Bitcoin Wallet",
    "description": "Enter your bitcoin address",
    "type": "text",
    "action": "copy",
    "logo": "btc"
  },
  "WS": {
    "displayName": "Website",
    "description": "Enter a website of your choice",
    "type": "url",
    "action": "open",
    "logo": "safari"
  },
  "VM": {
    "displayName": "Venmo",
    "description": "Enter your venmo username",
    "type": "text",
    "url": "https://venmo.com/",
    "action": "open",
    "logo": "venmo"
  },
  "PP": {
    "displayName": "Paypal",
    "url": "https://paypal.me/", 
    "type": "text",
    "description": "Enter your paypal me username",
    "action": "open",
    "logo": "paypal"
  },
  "FB": {
    "displayName": "Facebook",
    "description": "Enter your facebook url",
    "type": "url",
    "action": "open",
    "logo": "facebook"
  },
};

export const URLS = {
   PUBLIC_API: "https://creatorset.support:8080" 
   //PUBLIC_API: "http://localhost:8081"
};

export const setInput = (fn, lower) => (event) => {
    fn(lower ? event.target.value.toLowerCase() : event.target.value);
};

export const getAccessToken = () => {
    return localStorage["accessToken"];
};

export const getIdTokenPayload= () => {
    return localStorage["idToken_payload"];
};
