import { combineReducers } from 'redux';
import authReducer from '../../services/authService/reducer';
import profileReducer from '../../services/profileService/reducer';
import productReducer from '../../services/productService/reducer';
import productReviewsReducer from '../../services/productReviewsService/reducer';
import chatReducer from '../../services/chatService/reducer';

const allReducers = combineReducers({
    authReducer,
    profileReducer,
    productReducer,
    productReviewsReducer,
    chatReducer,
});

export default allReducers;