import React from 'react';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';


const Button = (props) => {
    return <button 
        disabled={props.loading}
        type={props.type}
        style={props.style}
        className={cx('default-button', props.className, '')}
        onClick={props.onClick}>
          { !props.loading && props.children }
          { props.loading && <CircularProgress color="white" style={({ height: '20px', width: '20px'})} /> }
      </button>
}

export default Button;