import { handleResponse, URLS, getAccessToken } from "../../utils";

export async function signup(email, name, password, code) {
    const req = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, password, name, code})
      };
    const url = URLS.PUBLIC_API + "/register";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }
  

  export async function confirm(payload) {
    const req = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      };
    const url = URLS.PUBLIC_API + "/confirme";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function login(email, password) {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password })
    };
    const url = URLS.PUBLIC_API + "/login";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function reauth(password) {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
      body: JSON.stringify(password)
    };
    const url = URLS.PUBLIC_API + "/reauth";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function getClientsResidentiels() {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
    const url = URLS.PUBLIC_API + "/getClientsResidentiels";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function getClientsAffaire() {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
    const url = URLS.PUBLIC_API + "/getClientsAffaire";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function resetPassword(data) {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
      body: JSON.stringify(data)
    };
    const url = URLS.PUBLIC_API + "/reset";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function forgotPassword(email) {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email)
    };
    const url = URLS.PUBLIC_API + "/forgot";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function updateMaxLoginAttempts(count) {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
      body: JSON.stringify({count})
    };
    const url = URLS.PUBLIC_API + "/updateAttemptsCount";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function getMaxLoginAttempts(count) {
    const req = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const url = URLS.PUBLIC_API + "/getMaxAttemptsCount";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function getMyInfo() {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
    const url = URLS.PUBLIC_API + "/getMyInfo";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }


  export async function logout() {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
    const url = URLS.PUBLIC_API + "/signOut";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function getProfile() {
    const req = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'BEARER ' + getAccessToken(),
      },
    };
    const url = URLS.PUBLIC_API + "/getProfile";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }