import { 
    GET_ORDER_BY_ID, 
    GET_ORDER_BY_ID_SUCCESS, 
    SAVE_PROPERTIES,
    SAVE_PROPERTIES_SUCCESS,
    GET_ORDER_DL_LINK,
    SEND_RERENDER_REQUEST,
    SEND_UPDATED_EMAIL,
    SEND_RERENDER_REQUEST_SUCCESS,
    SEND_UPDATED_EMAIL_SUCCESS,
    GET_RENDER_MESSAGE,
    GET_RENDER_MESSAGE_SUCCESS,
    SEND_TO_RENDER,
    SEND_TO_RENDER_SUCCESS,
    SAVE_CUSTOMER_EMAIL,
    SAVE_CUSTOMER_EMAIL_SUCCESS,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_PROGRESS,
    NEW_PROFILE,
    NEW_PROFILE_SUCCESS,
    CREATE_WEBM_REQUEST,
    CREATE_WEBM_REQUEST_SUCCESS,
    GET_ALL_PROFILES,
    GET_ALL_PROFILES_SUCCESS,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    GET_S_ORDER_DL_LINK,
    GET_ALL_PROFILES_NEW,
    UPDATE_PROFILE_NEW,
    UPDATE_PROFILE_NEW_SUCCESS
} from './actionTypes';

export const getOrderById = (orderId) => {
    return {
        type: GET_ORDER_BY_ID,
        orderId,
    };
};

export const getOrderByIdSuccess = (orders) => {
    return {
        type: GET_ORDER_BY_ID_SUCCESS,
        orders,
    };
};

export const saveProperties = (orderId, properties) => {
    return {
        type: SAVE_PROPERTIES,
        orderId,
        properties,
    };
};

export const savePropertiesSuccess = () => {
    return {
        type: SAVE_PROPERTIES_SUCCESS,
    };
};

export const getDownloadLink = (orderId) => {
    return {
        type: GET_ORDER_DL_LINK,
        orderId
    };
};

export const sendRerenderRequest = (itemIds, orderId) => {
    return {
        type: SEND_RERENDER_REQUEST,
        orderId,
        itemIds
    };
};

export const sendRerenderRequestSuccess = (orderId) => {
    return {
        type: SEND_RERENDER_REQUEST_SUCCESS,
        orderId
    };
};

export const sendUpdatedEmail = (orderId) => {
    return {
        type: SEND_UPDATED_EMAIL,
        orderId
    };
};

export const sendUpdatedEmailSuccess = (orderId) => {
    return {
        type: SEND_UPDATED_EMAIL_SUCCESS,
        orderId
    };
};

export const getRenderMessage = (orderId) => {
    return {
        type: GET_RENDER_MESSAGE,
        orderId
    };
};

export const getRenderMessageSuccess = () => {
    return {
        type: GET_RENDER_MESSAGE_SUCCESS,
    };
};

export const sendToRender = (orderId) => {
    return {
        type: SEND_TO_RENDER,
        orderId
    };
};

export const sendToRenderSuccess = () => {
    return {
        type: SEND_TO_RENDER_SUCCESS,
    };
};

export const saveCustomerEmail = (orderId, email) => {
    return {
        type: SAVE_CUSTOMER_EMAIL,
        orderId,
        email
    };
};

export const saveCustomerEmailSuccess = () => {
    return {
        type: SAVE_CUSTOMER_EMAIL_SUCCESS,
    };
};

export const uploadFile = (orderId, file, m) => {
    return {
        type: UPLOAD_FILE,
        orderId,
        file,
        m
    };
};

export const uploadFileProgress = (progress) => {
    return {
        type: UPLOAD_FILE_PROGRESS,
        progress
    };
};

export const uploadFileSuccess = () => {
    return {
        type: UPLOAD_FILE_SUCCESS,
    };
};

export const newProfile = (data, setLoading) => {
    return {
        type: NEW_PROFILE,
        data,
        setLoading
    }
}

export const newProfileSuccess = (data) => {
    return {
        type: NEW_PROFILE_SUCCESS,
        data,
    }
}

export const updateProfile = (data, setLoading) => {
    return {
        type: UPDATE_PROFILE,
        data,
        setLoading
    }
}

export const updateProfileSuccess = (data) => {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        data,
    }
}

export const createWebmRequest = (orderId) => {
    return {
        type: CREATE_WEBM_REQUEST,
        orderId
    }
}

export const createWebmRequestSuccess = () => {
    return {
        type: CREATE_WEBM_REQUEST_SUCCESS,
    }
}


export const getAllProfiles = () => {
    return {
        type: GET_ALL_PROFILES,
    }
}

export const getAllProfilesNew = () => {
    return {
        type: GET_ALL_PROFILES_NEW,
    }
}

export const getAllProfilesSuccess = (profiles) => {
    return {
        type: GET_ALL_PROFILES_SUCCESS,
        profiles
    }
}

export const getSingleDownloadLink = (id, itemId) => {
    return {
        type: GET_S_ORDER_DL_LINK,
        id, 
        itemId
    };
};

export const updateProfileNew = (data, setLoading) => {
    return {
        type: UPDATE_PROFILE_NEW,
        data,
        setLoading
    }
}

export const updateProfileNewSuccess = (data) => {
    return {
        type: UPDATE_PROFILE_NEW_SUCCESS,
        data,
    }
}