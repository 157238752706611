import { takeLatest, call, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { ROUTES } from '../../components/router/routes';
import { customHistory } from '../../components/router/router';
import { login, signup, confirm, reauth, logout } from './api';

async function setTokenInLocalstorage(refreshToken, accessToken, payload) {
    await localStorage.setItem('accessToken', accessToken);
    await localStorage.setItem('refreshToken', refreshToken); 
    await localStorage.setItem('idToken_payload', JSON.stringify(payload)); 
}

function* watchLogin(action) {
    try {
        const { email, password } = action;
        const  { result } = yield call(login, email, password);
        if (result) {
            const { refreshToken, idToken } = result;
            yield call(setTokenInLocalstorage, refreshToken.token, idToken.jwtToken, idToken.payload);
            yield put(actions.loginSuccess({ id: idToken.payload.aud, email: idToken.payload.email, name: idToken.payload.name }));

            try {
                customHistory.push(ROUTES.HOME);
            }
            catch(err) {
                console.log(err.error);
                if (err.error.message === "new-user") {
                    customHistory.push(ROUTES.SETUP);
                }
            }
        }
    }
    catch(e) {
        if (e.error && e.error.message === "ACCOUNT_BLOCKED") {
            alert("Your account is blocked because of many bad attemps. Contact the web administrator to get it unlocked.");
        }
    }
}

function* watchRegister(action) {
    try {
        const { email, name, password, role } = action;
        const { result } = yield call(signup, email, name, password, role);
        if (result) {
            if (result.code === "InvalidPasswordException") {
                console.log(result);
                alert(result.message);
            }
            else if (result.code === "UsernameExistsException") {
                alert(result.message);
            }
            else {
                customHistory.push(ROUTES.CONFIRM);
                yield put(actions.registerSuccess({ email, password }));
            }
        }
    }
    catch(e) {
    }
}

function* watchConfirm(action) {
    try {
        const { code, payload } = action;
        const { result } = yield call(confirm, { code, email: payload.email });

        if (result) {
            yield put(actions.loginRequest(payload.email, payload.password));
        }
    }catch (e) {

    }
}


function* watchReauth(action) {
    try {
        const { password } = action;
        const { result } = yield call(reauth, { password });
        
        if (result === "OK") {
            customHistory.push(ROUTES.ADMIN_SETTINGS, { validated: true });
        } else {
            alert("You don't have the rights to access this page.");
        }

    }catch (e) {
        if (e.error && e.error.message === "UNAUTHORIZED") {
            alert("You don't have the rights to access this page.");
        }
    }
}


function* watchLogout(action) {
    localStorage.setItem('accessToken', undefined);
    localStorage.setItem('refreshToken', undefined); 

    try {
        yield call(logout);
        customHistory.replace(ROUTES.LOGIN);
    }
    catch(err) {
    }
}

export default function* rootSaga() {
    yield takeLatest(actionTypes.LOGIN_REQUEST, watchLogin);
    yield takeLatest(actionTypes.REGISTER_REQUEST, watchRegister);
    yield takeLatest(actionTypes.CONFIRM_REQUEST, watchConfirm);
    yield takeLatest(actionTypes.REAUTH, watchReauth);
    yield takeLatest(actionTypes.LOGOUT, watchLogout);
}
