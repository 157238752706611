import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DefaultInput from '../../components/text-input';
import { setInput } from '../../utils';
import DefaultButton from '../../components/button';
import { Button, FormControlLabel, IconButton, MenuItem, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import PlusIcon from '@material-ui/icons/Add'
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
    form: {
        padding: '1em',
        maxWidth: 800,
        margin: "0 auto",
        width: '100%'
    },
    container: {
        width: 600,
        margin: '0 auto'
    },
    imgPlc: {
        width: '200px',
        background: "#e1e1e1",
        margin: '0 auto',
        marginBottom: "1em",
    },
    card: {
        marginBottom: '3em'
    },
    button: {
        marginTop: '1em'
    },
    picturePreview: {
        display: 'flex',
        flexDirection: 'column',
    },
    socialMedia: {
        textAlign: "left",
        marginBottom: "3em"
    },
    socialMediaList: {
        listStyleType: "none",
        padding: 0
    },
    socialMediaListItem: {
        display: "flex"
    },
    textField: {
        minWidth: "440px"
    },
    select: {
        width: "130px"
    }
});

function FileUpload(props) {

    const [updatePicture, setUpdatePicture] = React.useState(false);

    // Handles file upload event and updates state
    function handleUpload(event) {
        props.setFile(event.target.files[0]);
        if (props.requiresPreview) {
            try {
                props.setPreview(URL.createObjectURL(event.target.files[0]));
            }
            catch (e) { }
        }
    }

    const classes = useStyles();

    const { file, preview, picture_url } = props;
    return (
        <div id="upload-box" className={props.className}>
            {
                updatePicture &&
                <div>
                    {
                        !file && <div style={{ height: 200 }} className={classes.imgPlc}></div>
                    }
                    {
                        file && <div>
                            <Button size="small" onClick={() => { props.setFile(undefined); setUpdatePicture(false); props.requiresPreview && props.setPreview(picture_url); }}> Clear Selection </Button>
                        </div>
                    }
                    {
                        file && props.requiresPreview && file.type.indexOf("image") !== -1 && <div>
                            <img alt="image_preview" style={{ maxWidth: '200px', margin: '0.5em auto' }} src={preview} />
                        </div>
                    }
                    {
                        file && props.requiresPreview && file.type.indexOf("video") !== -1 && <div>
                            <video controls style={{ maxWidth: '200px', marginBottom: '0.5em' }} src={preview} />
                        </div>
                    }
                    {
                        file && !props.requiresPreview && <p> {file.name} </p>
                    }
                    <input accept={props.accept} type="file" onChange={handleUpload} />
                    {
                        !file &&
                        <Button size="small" onClick={() => { props.setFile(undefined); setUpdatePicture(false); props.setPreview(picture_url); }}> Cancel </Button>
                    }
                </div>
            }

            {
                !updatePicture && !file && props.preview && <div className={classes.picturePreview}>
                    <img alt="image_preview" style={{ maxWidth: '200px', margin: '0.5em auto' }} src={preview} />
                    <Button size="small" onClick={() => { setUpdatePicture(true); }}> Update Picture </Button>
                </div>
            }
        </div>
    );
}

export default function EditProfileForm(props) {

    const profile = props.location.state.data;
    const classes = useStyles();

    const [thumbnail, setThumbnail] = React.useState("");
    const [url, setUrl] = React.useState(profile.url);
    const [bio, setBio] = React.useState(profile.bio);
    const [verified, setVerified] = React.useState(profile.verified);
    const [displayName, setDisplayName] = useState(profile.displayName);
    const [thumbPreview, setPreviewThumb] = React.useState(profile.picture_url);
    const [socialMedia, setSocialMedia] = React.useState(profile.socialMedia);

    const submit = (e) => {

        e.preventDefault();

        const data = {
            displayName,
            url,
            verified,
            thumbnail,
            bio,
            socialMedia,
            id: profile.id,
            picture_url: profile.picture_url
        }

        if (!data.displayName || !url) {
            alert("You need to fill all required fields.");
            return;
        }

        if (data.socialMedia) {
            for (let index = 0; index < data.socialMedia.length; index++) {
                const element = data.socialMedia[index];

                if (!element.url || !element.platform || !element.url.trim()) {
                    alert("please properly enter the social media links");
                    return;
                }
            }
        }

        props.updateProfileNew(
            data,
        )
    };

    const onSocialChange = (index) => (event) => {
        const sm = socialMedia[index];
        sm.platform = event.target.value;
        setSocialMedia([...socialMedia]);
    };

    const handleSocialMediaChange = (index) => (event) => {
        const sm = socialMedia[index];
        sm.url = event.target.value;
        setSocialMedia([...socialMedia]);
    }

    const handleCheckbox = (event) => {
        setVerified(event.target.checked);
    }

    const newSocialLink = () => {
        var array = [...socialMedia];

        if (!array[array.length - 1].platform) {
            return;
        }

        array.push({ platform: "", url: "" });
        setSocialMedia(array);
    }

    const deleteSocialLink = (index) => () => {
        var array = [...socialMedia];
        array.splice(index, 1);

        setSocialMedia(array);
    }

    return (

        <div className={classes.form}>
            <div className={classes.card}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Edit Profile <span aria-label="img" role="img">💼</span>
                </Typography>
                <form onSubmit={submit} className={classes.container} noValidate autoComplete="off">
                    <div className={classes.uploadInput}>
                        <p>Profile Picture</p>
                        <FileUpload
                            className={classes.uploadBox}
                            accept="image/png, image/gif, image/jpeg"
                            file={thumbnail}
                            requiresPreview
                            preview={thumbPreview}
                            setPreview={setPreviewThumb}
                            setFile={setThumbnail}
                            picture_url={profile.picture_url}
                        />
                    </div>
                    <DefaultInput
                        className={classes.textField}
                        label="Display Name"
                        type="title"
                        name="display_name"
                        placeholder="Name as it should be displayed"
                        value={displayName}
                        onChange={setInput(setDisplayName)}
                    />
                    <DefaultInput
                        className={classes.textField}
                        label="Bio"
                        type="text"
                        name="bio"
                        value={bio}
                        placeholder="Optional"
                        onChange={setInput(setBio)}
                    />
                    <DefaultInput
                        className={classes.textField}
                        label="Profile Url"
                        type="text"
                        name="url_path"
                        placeholder="endpoint"
                        value={url}
                        pre={'https://creatorset.com/pages/workshop/'}
                        onChange={setInput(setUrl, true)}
                    />

                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={verified || false}
                            color="primary"
                            onChange={handleCheckbox}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />}
                        label="Verified Account"
                        labelPlacement="start"
                    />

                    <div className={classes.socialMedia}>
                        <b> Social Media Links </b>

                        <div>
                            <ul id="social-media-list" className={classes.socialMediaList}>
                                {socialMedia && socialMedia.map((i, index) => <li className={classes.socialMediaListItem}>
                                    <Select
                                        label="social"
                                        className={classes.select}
                                        value={i.platform}
                                        onChange={onSocialChange(index)}
                                    >
                                        <MenuItem value={"youtube"}>YouTube</MenuItem>
                                        <MenuItem value={"twitter"}>Twitter</MenuItem>
                                        <MenuItem value={"tiktok"}>TikTok</MenuItem>
                                        <MenuItem value={"twitch"}>Twitch</MenuItem>
                                        <MenuItem value={"instagram"}>Instagram</MenuItem>
                                    </Select>

                                    <DefaultInput
                                        type="text"
                                        name="url_path"
                                        value={i.url}
                                        onChange={handleSocialMediaChange(index)}
                                    />
                                    <IconButton aria-label="delete" color="primary" onClick={deleteSocialLink(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </li>)}
                            </ul>

                            <Button variant="contained" endIcon={<PlusIcon />} onClick={newSocialLink}>
                                Add Link
                            </Button>
                        </div>

                    </div>



                    <DefaultButton className={`default-button ${classes.button}`} type="submit">
                        Update Profile
                    </DefaultButton>
                </form>
            </div>
        </div>
    );
}