import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { setInput } from '../../utils';
import Button from '../../components/button';
import { ROUTES } from '../../components/router/routes';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
    width: '100%'
  },
  titleContainer: {
    marginBottom: '2em'
  },
  container: {
    width: '100%',
    marginBottom: '3em'
  },
  profileBox: {
    background: '#eee',
    padding: '5px',
    maxWidth: 500,
    margin: '0 auto',
    marginTop: '1em'
  },
  pic: {
    height: 150,
    width: 150,
    borderRadius: '50%',
    border: '3px solid #ccc'
  },
  menu: {
    marginTop: '0.5em'
  }
});

export default function Profiles(props) {
  const LINES_PER_PAGE = 10;
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    props.getAllProfilesNew();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  }

  const links = props.profiles && props.profiles.filter(f => !filter ? true : (f.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || ("/" + f.url.toLowerCase()).indexOf(filter.toLowerCase()) !== -1)).slice((page - 1) * LINES_PER_PAGE, page * LINES_PER_PAGE).map(profile => (
    <Link
      key={profile.id}
      to={{
        pathname: ROUTES.PROFILE_NEW,
        state: { data: profile }
      }}>
      <div className={classes.profileBox}>
        <img className={classes.pic} src={profile.picture_url || "https://d3c4b0rnzc49yh.cloudfront.net/p/3dc04545-37f0-41ce-b16e-78186c475ded.jpeg"} />
        <p> {profile.displayName} {profile.verified ? <img style={{ height: 16 }} src="https://cdn.shopify.com/s/files/1/0273/8080/9781/files/verified-creatorset.png?v=1647750139" /> : ""} </p> 
        <p> /{profile.url} </p>
      </div>
    </Link>
  ))

  return (
    <div className="profiles">
      <div className={classes.container}>
        <div className={classes.menu}>
          <Link
            to={{
              pathname: ROUTES.HOME,
            }}
          >
            <Button> Main Menu </Button>
          </Link>
        </div>
        <div>
          <p>Search Profile</p>
          <input value={filter} onChange={(event) => { setInput(setFilter(event.target.value)); setPage(1); }} />
        </div>
        {
          links
        }
        <div style={{ margin: "0 auto", width: "350px", padding: "1em" }}>
          <Pagination page={page} onChange={handleChange} count={props.profiles && Math.round(props.profiles.length / LINES_PER_PAGE)} color="primary" />
        </div>
      </div>
    </div>
  );
}