import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { setInput } from "../../utils";
import Button from "../../components/button";
import { ROUTES } from "../../components/router/routes";
import ProductReviewCard from "./product-review-card";

const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
    width: "100%",
  },
  titleContainer: {
    marginBottom: "2em",
  },
  container: {
    width: "100%",
    marginBottom: "3em",
  },
  profileBox: {
    background: "#eee",
    padding: "5px",
    maxWidth: 500,
    margin: "0 auto",
    marginTop: "1em",
    display: "flex",
  },
  pic: {
    maxHeight: 150,
    // borderRadius: '50%',
    border: "3px solid #ccc",
  },
  menu: {
    marginTop: "0.5em",
  },
  flex: {
    display: "flex",
  },
});

export default function ProductReviews(props) {
  const PER_PAGE = 20;
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    props.getAllReviewableProducts();
  }, [props.approvedResponse]);

  const showApprovedResponse = async () => {
    const { message } = props.approvedResponse;
    console.log("message ", message);
    await props.dismissMessages();
    alert(message);
  };
  
  const showCreateTicketResponse = async () => {
    const { status } = props.createTicketResponse;
    console.log("message ", status);
    await props.dismissMessages();
    alert(status);
  };

  const showRejectedResponse = async () => {
    const { message } = props.rejectedResponse;
    console.log("message ", message);
    await props.dismissMessages();
    alert(message);
  };
  
  useEffect(() => {
    if (props.createTicketResponse) {
      showCreateTicketResponse(); 
      }
  }, [props.createTicketResponse]);

  useEffect(() => {
    if (props.approvedResponse) {
      showApprovedResponse();
    }
  }, [props.approvedResponse]);

  useEffect(() => {
    if (props.rejectedResponse) {
      showRejectedResponse();
    }
  }, [props.rejectedResponse]);

  return (
    <div className="profiles">
      <div className={classes.container}>
        <div className={classes.menu}>
          <Link
            to={{
              pathname: ROUTES.HOME,
            }}
          >
            <Button> Main Menu </Button>
          </Link>
        </div>
        <div>
          <p>Ready For Review</p>
          <input value={filter} onChange={setInput(setFilter)} />
        </div>
        {props.reviewableProducts &&
          props.reviewableProducts
            .filter((f) =>
              !filter
                ? true
                : f.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            )
            .map((product) => (
              <ProductReviewCard product={product} {...props} />
            ))}
      </div>
    </div>
  );
}
