import {
  GET_ALL_REVIEWABLE_PRODUCTS_SUCCESS,
  REJECT_PRODUCT_SUCCESS,
  APPROVE_PRODUCT_SUCCESS,
  DISMISS_MESSAGES,
} from "./actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_REVIEWABLE_PRODUCTS_SUCCESS:
      return {
        ...state,
        reviewableProducts: action.reviewableProducts,
      };
    case REJECT_PRODUCT_SUCCESS:
      return {
        ...state,
        rejectedResponse: action.message,
      };
    case APPROVE_PRODUCT_SUCCESS:
      return {
        ...state,
        approvedResponse: action.message,
      };
    case DISMISS_MESSAGES:
      return {
        ...state,
        approvedResponse: null,
        rejectedResponse: null,
      };
    default:
      return state;
  }
};
