import { LOGIN_REQUEST, 
    LOGIN_SUCCESS, 
    REGISTER_REQUEST, 
    REGISTER_SUCCESS,
    CONFIRM_REQUEST, 
    GET_CLIENTS_RESIDENTIELS, 
    GET_CLIENTS_RESIDENTIELS_SUCCESS, 
    GET_CLIENTS_AFFAIRES, 
    GET_CLIENTS_AFFAIRES_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    REAUTH,
    REAUTH_SUCCESS,
    FORGOT_PASSWORD,
    UPDATE_LOGIN_ATTEMPTS,
    GET_MAX_LOGIN_ATTEMPTS,
    GET_MAX_LOGIN_ATTEMPTS_SUCCESS,
    LOGOUT,
    GET_MY_INFO_SUCCESS,
    GET_MY_INFO
} from './actionTypes';

export const loginRequest = (email, password) => {
    return {
        type: LOGIN_REQUEST,
        email,
        password
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        user,
    }
}

export const registerRequest = (email, name, password, role) => {
    return {
        type: REGISTER_REQUEST,
        email,
        name,
        password,
        role
    }
}

export const registerSuccess = (user) => {
    return {
        type: REGISTER_SUCCESS,
        user,
    }
}

export const confirmRequest = (code, payload) => {
    return {
        type: CONFIRM_REQUEST,
        code,
        payload
    }
}

export const getClientResidentiels = () => {
    return {
        type: GET_CLIENTS_RESIDENTIELS
    }
}

export const getClientResidentielsSuccess = (clients) => {
    return {
        type: GET_CLIENTS_RESIDENTIELS_SUCCESS,
        clients
    }
}

export const getClientAffaire = () => {
    return {
        type: GET_CLIENTS_AFFAIRES
    }
}

export const getClientAffaireSuccess = (clients) => {
    return {
        type: GET_CLIENTS_AFFAIRES_SUCCESS,
        clients,
    }
}

export const resetPassword = (email, code, password) => {
    return {
        type: RESET_PASSWORD,
        email,
        code,
        password
    }
}

export const resetPasswordSuccess = (email, code, password) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
    }
}


export const reauth = (password) => {
    return {
        type: REAUTH,
        password
    }
}

export const reauthSuccess = () => {
    return {
        type: REAUTH_SUCCESS,
    }
}

export const forgotPassword = (email) => {
    return {
        type: FORGOT_PASSWORD,
        email
    }
}

export const updateLoginAttemptsCount = (count) => {
    return {
        type: UPDATE_LOGIN_ATTEMPTS,
        count
    }
}


export const getMaxAttemptCount = () => {
    return {
        type: GET_MAX_LOGIN_ATTEMPTS,
    }
}


export const getMaxAttemptCountSuccess = (count) => {
    return {
        type: GET_MAX_LOGIN_ATTEMPTS_SUCCESS,
        count
    }
}

export const getMyInfo = () => {
    return {
        type: GET_MY_INFO,
    }
}


export const getMyInfoSuccess = (user) => {
    return {
        type: GET_MY_INFO_SUCCESS,
        user
    }
}


export const logout = () => {
    return {
        type: LOGOUT,
    }
}