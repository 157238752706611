export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";

export const SAVE_PROPERTIES = "SAVE_PROPERTIES";
export const SAVE_PROPERTIES_SUCCESS = "SAVE_PROPERTIES_SUCCESS";

export const GET_ORDER_DL_LINK = "GET_ORDER_DL_LINK";
export const GET_ORDER_DL_LINK_SUCCESS = "GET_ORDER_DL_LINK_SUCCESS";

export const SEND_RERENDER_REQUEST = "SEND_RERENDER_REQUEST";
export const SEND_RERENDER_REQUEST_SUCCESS = "SEND_RERENDER_REQUEST_SUCCESS";

export const SEND_UPDATED_EMAIL = "SEND_UPDATED_EMAIL";
export const SEND_UPDATED_EMAIL_SUCCESS = "SEND_UPDATED_EMAIL_SUCCESS";

export const GET_RENDER_MESSAGE = "GET_RENDER_MESSAGE";
export const GET_RENDER_MESSAGE_SUCCESS = "GET_RENDER_MESSAGE_SUCCESS";

export const SEND_TO_RENDER = "SEND_TO_RENDER";
export const SEND_TO_RENDER_SUCCESS = "SEND_TO_RENDER_SUCCESS";

export const SAVE_CUSTOMER_EMAIL = "SAVE_CUSTOMER_EMAIL";
export const SAVE_CUSTOMER_EMAIL_SUCCESS = "SAVE_CUSTOMER_EMAIL_SUCCESS";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";

export const NEW_PROFILE = "NEW_PROFILE";
export const NEW_PROFILE_SUCCESS = "NEW_PROFILE_SUCCESS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const UPDATE_PROFILE_NEW = "UPDATE_PROFILE_NEW";
export const UPDATE_PROFILE_NEW_SUCCESS = "UPDATE_PROFILE_NEW_SUCCESS";

export const CREATE_WEBM_REQUEST = "CREATE_WEBM_REQUEST";
export const CREATE_WEBM_REQUEST_SUCCESS = "CREATE_WEBM_REQUEST_SUCCESS";

export const GET_ALL_PROFILES = "GET_ALL_PROFILES";
export const GET_ALL_PROFILES_SUCCESS = "GET_ALL_PROFILES_SUCCESS";

export const GET_ALL_PROFILES_NEW = "GET_ALL_PROFILES_NEW";
export const GET_ALL_PROFILES_NEW_SUCCESS = "GET_ALL_PROFILES_NEW_SUCCESS";

export const GET_S_ORDER_DL_LINK = "GET_S_ORDER_DL_LINK";
export const GET_S_ORDER_DL_LINK_SUCCESS = "GET_S_ORDER_DL_LINK_SUCCESS";