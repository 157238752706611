import React, { useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import Accordion from './components/accordion';
import { customHistory } from '../../components/router/router';
import { ROUTES } from '../../components/router/routes';
const Profile = (props) => {

    useEffect(() => {
        if (!props.maxAttempts) {
            props.getMaxAttemptCount();
        }
        if (!props.location.state || !props.location.state.validated) {
            alert("You need to authenticate first.");
            customHistory.push(ROUTES.HOME);
        }
      }, [])

    return (
        <Container style={{textAlign: "left"}} maxWidth="md">
            <Typography variant="h5" component="h2">Admin Settings</Typography>
            <div style={{marginTop: "1em"}}>
                {
                    props.maxAttempts &&
                    <Accordion 
                        {...props}
                    />
                }

            </div>
        </Container>
    );
}

export default Profile;