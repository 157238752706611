import { takeLatest, call, put, take } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
    createTicket
} from './api';
import { createTicketSuccess} from './actions';

function* watchCreateTicket(action) {
    try {
        const r = yield call(createTicket, {product_id: action.product_id, message: action.message, title: action.title});

        if (r) {
            yield put(createTicketSuccess(r.result));
        }
    }
    catch (e) {
        console.log(e);
        alert("An error occured. Make sure email is valid.");
    }
}

export default function* rootSaga() {
    yield takeLatest(actionTypes.CREATE_TICKET, watchCreateTicket);
}
