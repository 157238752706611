import React, { useEffect } from 'react';

const input = React.forwardRef((props, ref) => {

    useEffect(() => {
        if (props.focus) {
            ref.current.focus();
        }
    });

    return <div className="default-input-wrapper">
        {
            props.label && 
            <label>
                { props.label }
            </label>
        }
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {
                props.pre &&
                <div style={{fontSize: '0.9em'}}>
                    { props.pre }
                </div>
            }

            <input
                ref={ref} 
                className="tap2-input"
                id={props.id}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value}
                type={props.type}
                name={props.name}
                onChange={props.onChange}
            />
        </div>
    </div>
});

export default input;