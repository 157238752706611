import ProductReviews from "./product-reviews";
import { connect } from "react-redux";
import {
  getAllReviewableProducts,
  approveProduct,
  rejectProduct,
  dismissMessages,
} from "../../services/productReviewsService/actions";
import { getProductDLink } from '../../services/productService/actions';
import { createTicket,dismissMessages as dismissCreateTicket } from '../../services/chatService/actions';

export default connect(
  (state) => ({
    reviewableProducts: state.productReviewsReducer.reviewableProducts,
    approvedResponse: state.productReviewsReducer.approvedResponse,
    rejectedResponse: state.productReviewsReducer.rejectedResponse,
    createTicketResponse: state.chatReducer.createTicketResponse,
  }),
  { getAllReviewableProducts, approveProduct, rejectProduct, dismissMessages, getProductDLink, createTicket, dismissCreateTicket  }
)(ProductReviews);
