import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DefaultInput from '../../components/text-input';
import { setInput } from '../../utils';
import DefaultButton from '../../components/button';
import { Button } from '@material-ui/core';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  form: {
    padding: '1em',
    maxWidth: 800,
    margin: "0 auto",
    width: '100%'
  },
  container: {
    width: 500,
    margin: '0 auto'
  },
  uploadInput: {
    textAlign: "left"
  },
  button: {
    marginTop: '3em',
    marginBottom: '3em'
  },
  loading: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
    color: 'white'
  },
  uploadBox: {
    marginTop: '0.5em',
    padding: '1em',
    textAlign: 'center',
    background: '#f3f3f3',
    borderRadius: '1em',
  }
});

function FileUpload(props) {
    // Handles file upload event and updates state
    function handleUpload(event) {
      props.setFile(event.target.files[0]);
      if (props.requiresPreview) {
        props.setPreview(URL.createObjectURL(event.target.files[0]));
      }
    }

    const { file, preview } = props;
    return (
      <div id="upload-box" className={props.className}>
        {
            file && <div>
                <Button size="small" onClick={() => { props.setFile(undefined); props.requiresPreview && props.setPreview(undefined); }}> Clear Selection </Button>
            </div>
        }
        {
            file && props.requiresPreview && file.type.indexOf("image") !== -1 && <div>
               <img alt="img" style={{maxWidth: '200px', marginBottom: '0.5em'}} src={ preview } />
            </div>
        }
        {
            file && props.requiresPreview && file.type.indexOf("video") !== -1 && <div>
                <video controls style={{maxWidth: '200px', marginBottom: '0.5em'}} src={ preview } />
            </div>
        }
        {
            file && !props.requiresPreview && <p> {file.name} </p>
        }

        <input accept={props.accept} type="file" onChange={handleUpload} />
      </div>
    );
  }

export default function NewProductForm(props) {

    useEffect(() => {
        props.getFormEntitiesRequest()
      }, [])

  const classes = useStyles();

  const [thumbnail, setThumbnail] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [file, setFile] = React.useState("");
  const [title, setTitle] = useState("");
  const [sku, setSku] = useState("GSS-");
  const [price, setPrice] = useState(9.99);
  const [priceCompare, setPriceCompare] = useState(10.99);
  const [selectedVendors, setVendors] = useState({});
  const [selectedCollections, setCollections] = useState([]);
  const [description, setDescription] = useState("");

  const [thumbPreview, setPreviewThumb] = React.useState("");
  const [videoPreview, setPreviewVideo] = React.useState("");
  const [loading, setLoading] = useState(false);

  const createNewProduct = (e) => {

    e.preventDefault(); 
    const data = {
        title,
        sku,
        price,
        priceCompare,
        description,
        thumbnail,
        video,
        collections: selectedCollections.map(col => col.value),
        seller: selectedVendors,
        file
    }

    if (!data.title || !data.sku || !data.price || !data.priceCompare || !data.description || !data.file || !data.thumbnail || !data.video) {
        alert("You need to fill all fields11!11!");
        return;
    }
    
    setLoading(true);

    props.newGreenScreenRequest(
        data,
        setLoading
    )
  };

  const entities = (props.formEntities || { collections: [], vendors: [] });
  const vendors = entities.vendors.map( v => ({ value: v, label: v }) );
  const collections = entities.collections.map( col => ({ value: col.id, label: col.title }) );

  const onVendorsChange = (newValue) => {
     setVendors(newValue.value);
    }

  const onCollectionChange = (newValue) => {
     setCollections(newValue);
   }

  return (

      <div className={classes.form}>

        { 
            loading && <div className={classes.loading}>
                <p>Uploading Files to Server. Could take a few minutes...  </p>
                <CircularProgress color="white" style={({ marginLeft: '1em', height: '20px', width: '20px'})} />
            </div> 
        }

        <div className={classes.card}>
            <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                New Product
            </Typography>
            <form onSubmit={createNewProduct} className={classes.container} noValidate autoComplete="off">
                <DefaultInput 
                    className={classes.textField}
                    label="Product Title"
                    type="title"
                    name="title"
                    autoComplete="title"
                    value={title}
                    onChange={setInput(setTitle)}
                />
                <div className="default-input-wrapper">
                    {
                        <label>
                            Description
                        </label>
                    }
                    <textarea className="tap2-input" onChange={setInput(setDescription)} value={description} aria-label="Bio" rowsMin={3} placeholder="Description" />
                </div>

                <DefaultInput 
                    className={classes.textField}
                    label="Price"
                    type="number"
                    name="price"
                    value={price}
                    onChange={setInput(setPrice)}
                />
                <DefaultInput 
                    className={classes.textField}
                    label="Price Compared"
                    type="number"
                    name="priceCompareTo"
                    value={priceCompare}
                    onChange={setInput(setPriceCompare)}
                />
                <DefaultInput 
                    className={classes.textField}
                    label="SKU"
                    type="text"
                    name="sku"
                    value={sku}
                    onChange={setInput(setSku)}
                />
                <div className="default-input-wrapper">
                    {
                        <label>
                            Seller
                        </label>
                    }
                    <CreatableSelect 
                        options={vendors} 
                        onChange={onVendorsChange}
                    />
                </div>

                <div className="default-input-wrapper">
                    {
                        <label>
                            Collection
                        </label>
                    }
                    <Select 
                        isSearchable={false}
	  		            isMulti 
                        options={collections} 
                        onChange={onCollectionChange}
                    />
                </div>
                <div>
                    <div className={classes.uploadInput}>
                        <p>Thumbnail</p>
                        <FileUpload 
                            className={classes.uploadBox}
                            accept="image/png, image/gif, image/jpeg"
                            file={thumbnail}
                            requiresPreview
                            preview={thumbPreview}
                            setPreview={setPreviewThumb}
                            setFile={setThumbnail}
                        />
                    </div>
                
                    <div className={classes.uploadInput}>
                        <p>Video</p>
                        <FileUpload 
                        className={classes.uploadBox}
                            accept="video/mp4, video/webm"
                            file={video}
                            requiresPreview
                            preview={videoPreview}
                            setPreview={setPreviewVideo}
                            setFile={setVideo}
                        />
                    </div>

                    <div className={classes.uploadInput}>
                        <p>Files</p>
                        <p>Select a .zip or .mp4 file</p>
                        <FileUpload 
                        className={classes.uploadBox}
                            accept="*"
                            file={file}
                            setFile={setFile}
                        />
                    </div>
                </div>
               
                <DefaultButton className={`default-button ${classes.button}`} type="submit">
                    Save Changes
                </DefaultButton>
            </form>
        </div>
</div>
  );
}
