import { GET_ALL_PROFILES_SUCCESS, GET_ORDER_BY_ID_SUCCESS, GET_ALL_PROFILES_NEW_SUCCESS } from './actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        orders: action.orders,
      };
    case GET_ALL_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.profiles,
      };
    case GET_ALL_PROFILES_NEW_SUCCESS:
      return {
        ...state,
        profiles: action.profiles,
      };
    default:
      return state;
  }
};
