import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { setInput } from '../../utils';
import TextInput from '../../components/text-input';
import { ROUTES } from '../../components/router/routes';
import { Button } from '@material-ui/core';
import { customHistory } from '../../components/router/router';


const useStyles = makeStyles(theme => ({
  root: {
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderBottom: "1px solid #eee"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '32px',
    position: 'relative',
    left: 9
  }
}));

function ButtonAppBar(props) {

  const [query, setQuery] = useState("");

  const classes = useStyles();
  
  /*const logout = (e) => {
    e.preventDefault();
    props.logout();
  };*/

  const search = () => {
    console.log(query);
    props.getOrderById(query);
    customHistory.push(ROUTES.HOME)
  }
  
  return (

    <div className={classes.root}>
      <TextInput 
        onChange={setInput(setQuery)}
        placeholder={"Search for order"}
        value={query}
      />
      <Button onClick={search}>Search</Button>
    </div>
  );
}

export default ButtonAppBar;