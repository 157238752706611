import { takeLatest, call, put, take } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
    getOrderById,
    updateProperties,
    getDownloadLink,
    createWebmRequest,
    sendUpdatedEmail,
    sendRerenderRequest,
    getRenderMessage,
    sendToQueueRequest,
    updateEmail,
    getUploadUrl,
    uploadFileChunk,
    newProfile,
    getAllProfiles,
    updateProfile,
    getSingleDownloadLink,
    getAllProfilesNew,
    updateProfileNew
} from './api';
import { getOrderByIdSuccess, getAllProfilesSuccess } from './actions';
import { customHistory } from '../../components/router/router';
import { ROUTES } from '../../components/router/routes';

function* watchGetOrderById(action) {
    try {
        const { result } = yield call(getOrderById, action.orderId);

        if (result) {
            yield put(getOrderByIdSuccess(result.orders));
        }
    }
    catch (e) {
        alert("An error occured. You may have insufficient rights.")
    }
}

function* watchGetRenderMessage(action) {
    try {
        const { result } = yield call(getRenderMessage, action.orderId);
        if (result) {

            var copyText = document.getElementById("download-url");
            copyText.value = JSON.stringify(result);
            console.log("copy", copyText);
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            alert("Copied.");
        }
    }
    catch (e) {

    }
}

function* watchSaveProperties(action) {
    try {
        const { result } = yield call(updateProperties, action.orderId, action.properties);

        if (result) {
            yield put(getOrderByIdSuccess(result.orders));
        }
    }
    catch (e) {
    }
}

function* watchGetDownloadLink(action) {
    try {
        const { result } = yield call(getDownloadLink, action.orderId);

        var copyText = document.getElementById("download-url");

        copyText.value = result;

        console.log("copy", copyText);

        copyText.select();

        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");


        /* Alert the copied text */

        alert("Copied.");

    }
    catch (e) {

    }
}



function* watchSendUpdatedEmail(action) {
    try {
        const { result } = yield call(sendUpdatedEmail, action.orderId);

        if (result) {
            alert("The email was sent.");
        }
    }
    catch (e) {

    }
}

function* watchCreateWebmRequest(action) {
    try {
        console.log(action);
        const { result } = yield call(createWebmRequest, action.orderId);
        if (result) {
            alert("The request was sent. An email will be sent to the client once done.");
        }
    }
    catch (e) {

    }
}

function* watchSendRerenderRequest(action) {
    try {
        console.log(action);
        const { result } = yield call(sendRerenderRequest, action.orderId, action.itemIds);

        if (result) {
            alert("The request was sent. An email will be sent to the client once done.");
        }
    }
    catch (e) {
    }
}

function* watchSendToQueue(action) {
    try {
        console.log(action);
        const { result } = yield call(sendToQueueRequest, action.orderId);

        if (result) {
            alert("The request was added to the queue.");
        }
    }
    catch (e) {
        alert("Order already fulfilled.");
    }
}

function* watchSaveEmail(action) {
    try {
        console.log(action);
        const { result } = yield call(updateEmail, action.orderId, action.email);

        if (result) {
            alert("The email was updated.");
        }
    }
    catch (e) {
        alert("An error occured. Make sure email is valid.");
    }
}

function* watchUploadFile(action) {
    try {
        const { result: presignedUrl } = yield call(getUploadUrl, action.orderId, action.m);

        const onProgressCb = (percentage) => {
            console.log(percentage);
        };

        const { result: uploadResult } = yield call(uploadFileChunk, action.file, presignedUrl.url, onProgressCb, true);

        if (uploadResult) {
            alert("Uploaded Successfully!");
        }
    }
    catch (e) {
        alert("Upload Failed/Invalid Password");
    }
}

function* watchNewProfile(action) {
    try {
        const { data } = action;
        const formData = new FormData();

        for (const name in data) {
            formData.append(name, data[name]);
        }
        const r = yield call(newProfile, formData);

        if (r) {
            alert("The profile was created.");
            yield call(customHistory.push, "/orders");
        }
    }
    catch (e) {
        alert("An error occured. Make sure email is valid and that the profile url is not already taken.");
    }
}

function* watchUpdateProfile(action) {
    try {
        const { data } = action;
        const formData = new FormData();

        for (const name in data) {
            formData.append(name, data[name]);
        }
        try {
            const r = yield call(updateProfile, formData);
            if (r) {
                alert("The profile was updated.");
                yield call(customHistory.push, "/orders");
            }
        }
        catch (e) {
            alert("An error occured. The profile url seems to be already in use.")
        }
    }
    catch (e) {
        alert("An error occured. Make sure email is valid.");
    }
}

function* watchGetProfiles(action) {
    try {
        const r = yield call(getAllProfiles);

        if (r) {
            console.log(r);
            yield put(getAllProfilesSuccess(r.result));
        }
    }
    catch (e) {
        console.log(e);
        alert("An error occured. Make sure email is valid.");
    }
}

function* watchGetProfilesNew(action) {
    try {
        const r = yield call(getAllProfilesNew);

        if (r) {
            console.log(r);
            yield put(getAllProfilesSuccess(r.result));
        }
    }
    catch (e) {
        console.log(e);
        alert("An error occured. Make sure email is valid.");
    }
}

function* watchGetSingleDownloadLink(action) {
    try {
        const { result } = yield call(getSingleDownloadLink, action.id, action.itemId);
        var copyText = document.getElementById("download-url");
        copyText.value = result;
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        alert("Copied.");
    }
    catch (e) {

    }
}

function* watchUpdateProfileNew(action) {
    try {
        const { data } = action;
        const formData = new FormData();

        for (const name in data) {
            if (name === "socialMedia") {
                formData.append(name, JSON.stringify(data[name]));
            }
            else {
                if (typeof data[name] !== 'undefined') {
                    formData.append(name, data[name]);
                }
            }
        }
        try {
            const r = yield call(updateProfileNew, formData);
            if (r) {
                alert("The profile was updated.");
                yield call(customHistory.push, ROUTES.PROFILES_NEW);
            }
        }
        catch (e) {
            alert("An error occured. The profile url seems to be already in use.")
        }
    }
    catch (e) {
        alert("An error occured. Make sure email is valid.");
    }
}

export default function* rootSaga() {
    yield takeLatest(actionTypes.GET_ORDER_BY_ID, watchGetOrderById);
    yield takeLatest(actionTypes.SAVE_PROPERTIES, watchSaveProperties);
    yield takeLatest(actionTypes.GET_ORDER_DL_LINK, watchGetDownloadLink);
    yield takeLatest(actionTypes.SEND_UPDATED_EMAIL, watchSendUpdatedEmail);
    yield takeLatest(actionTypes.SEND_RERENDER_REQUEST, watchSendRerenderRequest);
    yield takeLatest(actionTypes.GET_RENDER_MESSAGE, watchGetRenderMessage);
    yield takeLatest(actionTypes.SEND_TO_RENDER, watchSendToQueue);
    yield takeLatest(actionTypes.SAVE_CUSTOMER_EMAIL, watchSaveEmail);
    yield takeLatest(actionTypes.UPLOAD_FILE, watchUploadFile);
    yield takeLatest(actionTypes.NEW_PROFILE, watchNewProfile);
    yield takeLatest(actionTypes.CREATE_WEBM_REQUEST, watchCreateWebmRequest);
    yield takeLatest(actionTypes.GET_ALL_PROFILES, watchGetProfiles);
    yield takeLatest(actionTypes.UPDATE_PROFILE, watchUpdateProfile);
    yield takeLatest(actionTypes.GET_S_ORDER_DL_LINK, watchGetSingleDownloadLink);
    yield takeLatest(actionTypes.GET_ALL_PROFILES_NEW, watchGetProfilesNew);
    yield takeLatest(actionTypes.UPDATE_PROFILE_NEW, watchUpdateProfileNew);
}
