import { LOAD_FORM_ENTITIES_SUCCESS, NEW_GREEN_SCREEN_SUCCESS, GET_PRODUCT_DL_LINK_SUCCESS, LOAD_ANIMATION_INSTRUCTIONS_SUCCESS, CLEAR_INSTRUCTIONS } from './actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NEW_GREEN_SCREEN_SUCCESS:
      return {
        ...state,
      }
    case LOAD_FORM_ENTITIES_SUCCESS:
      return {
        ...state,
        formEntities: action.data,
      }
    case GET_PRODUCT_DL_LINK_SUCCESS:
      return {
        ...state,
        productDL: action.data,
      }
    case LOAD_ANIMATION_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        instructions: action.instructions === "OK" ? undefined : action.instructions,
      }
    case CLEAR_INSTRUCTIONS:
      return {
        ...state,
        instructions: undefined,
      }
    default:
      return state;
  }
};
