import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DefaultInput from '../../components/text-input';
import { setInput } from '../../utils';
import DefaultButton from '../../components/button';
import { Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: '1em'
    },
    button: {
        marginBottom: '1em'
    },
    actions: {
        textAlign: "right"
    },
    order: {
        width: 500,
        background: '#eaeaea',
        margin: '0 auto',
        borderRadius: 3,
        padding: '1em',
        marginBottom: '1em',
        maxWidth: '100%'
    }
}));

function FileUpload(props) {
    // State to store uploaded file
    const [file, setFile] = React.useState("");
  
    // Handles file upload event and updates state
    function handleUpload(event) {
      setFile(event.target.files[0]);
    }

    return (
      <div id="upload-box" style={{ marginTop: "0.5em" }}>
        <input accept="application/zip" type="file" onChange={handleUpload} />
        {
            file && <div>
                <p>Filename: {file.name}</p>
                <DefaultButton
                    loading={props.loading}
                    type="button"
                    onClick={props.submitFile(file)}
                    style={{ margin: "10px auto", marginTop: 0 }}
                    className="default-button">
                    Update File
                </DefaultButton>
                <Button onClick={() => setFile(undefined)}> Cancel</Button>
            </div>
        }
      </div>
    );
  }

const Products = (props) => {
    const [sku, setSku] = useState("");
    const [skuDl, setSkuDl] = useState("");
    const [copy, setCopyText] = useState("Copy");
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const { productDL } = props;

    const submit = () => {
        if (!sku) {
            alert("please enter an sku.");
            return
        }
        const pwd = window.prompt("You are entering a prohibited area. Please enter password to proceed.");
        if (!pwd) {
            alert("Access denied.")
            return
        }
        setLoading(true);
        props.getProductDLink(sku, pwd, setLoading, (r) => {
         if (r.result != "Invalid Request.") {
            var copyText = document.getElementById("download-url");
            copyText.value = r.result; 
            copyText.select(); 
            copyText.setSelectionRange(0, 99999);
         }
        });
    }

    const submitFile = (file) => () => {
        if (!skuDl) {
            alert("please enter an sku.");
            return
        }
        const pwd = window.prompt("You are entering a prohibited area. Please enter password to proceed.");
        if (!pwd) {
            alert("Access denied.")
            return
        }
        setLoadingUpload(true);
        props.updateProductFile(skuDl, pwd, file, setProgress, setLoadingUpload);
    }

    const copyClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(productDL).then(function() {
                setCopyText("Copied!")
            }, function() {
                setCopyText("Couldn't Copy")
            });
            setTimeout(function() {
                setCopyText("Copy")
            }, 3500)
        }
    }

    const classes = useStyles();
    useEffect(() => {
        //TODO: get unfulfilled orders
    }, []);

    return (
        <div className={classes.root}>
            <div style={{ display: "flex", flexDirection: "column", width: 500, margin: "0 auto" }}>
                <h2>Test Product</h2>
                <p>Enter Product SKU</p>
                <DefaultInput
                    className={classes.textField}
                    label="SKU"
                    type="text"
                    name="sku"
                    autoComplete="text"
                    value={sku}
                    onChange={setInput(setSku)}
                />
                <DefaultButton
                    loading={loading}
                    type="button"
                    onClick={submit}
                    style={{ margin: "10px auto", marginTop: 0 }}
                    className="default-button">
                    Get Download Link
                </DefaultButton>
                <textarea style={{ width: "100%", height: "100px" }} value={productDL} id="download-url" type="text" readOnly />
                {
                    productDL &&
                    <DefaultButton
                        loading={loading}
                        type="button"
                        onClick={copyClipboard}
                        style={{ margin: "10px auto" }}
                        className="default-button">
                        {copy}
                    </DefaultButton>
                }
            </div>

            <div style={{ display: "flex", flexDirection: "column", width: 500, margin: "0 auto" }}>
                <h2>Update Product File</h2>
                <p>Enter Product SKU</p>

                <DefaultInput
                    className={classes.textField}
                    label="SKU"
                    type="text"
                    name="sku"
                    autoComplete="text"
                    value={skuDl}
                    onChange={setInput(setSkuDl)}
                />
                <div className={classes.theBox}>
                    {
                        loadingUpload &&
                        <CircularProgress variant="determinate" value={progress} />
                    }
                    {
                        <FileUpload   
                            loading={loadingUpload}
                            submitFile={submitFile}
                        />
                    }
                </div>
            </div>

        </div>
    );
}

export default Products;