export const NEW_GREEN_SCREEN = "NEW_GREEN_SCREEN";
export const NEW_GREEN_SCREEN_SUCCESS = "NEW_GREEN_SCREEN_SUCCESS";

export const LOAD_FORM_ENTITIES = "LOAD_FORM_ENTITIES";
export const LOAD_FORM_ENTITIES_SUCCESS = "LOAD_FORM_ENTITIES_SUCCESS";

export const GET_PRODUCT_DL_LINK = "GET_PRODUCT_DL_LINK";
export const GET_PRODUCT_DL_LINK_SUCCESS = "GET_PRODUCT_DL_LINK_SUCCESS";

export const UPDATE_PRODUCT_FILE = "UPDATE_PRODUCT_FILE";
export const UPDATE_PRODUCT_FILE_SUCCESS = "UPDATE_PRODUCT_FILE_SUCCESS";

export const LOAD_ANIMATION_INSTRUCTIONS = "LOAD_ANIMATION_INSTRUCTIONS";
export const LOAD_ANIMATION_INSTRUCTIONS_SUCCESS = "LOAD_ANIMATION_INSTRUCTIONS_SUCCESS";

export const SAVE_ANIMATION_INSTRUCTIONS = "SAVE_ANIMATION_INSTRUCTIONS";
export const SAVE_ANIMATION_INSTRUCTIONS_SUCCESS = "SAVE_ANIMATION_INSTRUCTIONS_SUCCESS";

export const CLEAR_INSTRUCTIONS = "CLEAR_INSTRUCTIONS";