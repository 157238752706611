import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";

import DefaultButton from "../../components/button";
import Button from "../../components/button";
import { ROUTES } from "../../components/router/routes";
import { Link } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getProductById } from "../../services/productService/api";
const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
    width: "100%",
  },
  titleContainer: {
    marginBottom: "2em",
  },
  container: {
    width: "100%",
    marginBottom: "2em",
  },
  profileBox: {
    background: "#eee",
    padding: "5px",
    maxWidth: 500,
    margin: "0 auto",
    marginTop: "1em",
  },
  pic: {
    maxHeight: 150,
    // borderRadius: '50%',
    border: "3px solid #ccc",
  },
  assignBtn: {
    marginTop: "1em",
  },
  options: {
    display: "flex",
    flexDirection: "column",
  },
  menu: {
    marginTop: "0.5em",
    marginBottom: "3em",
  },
  flex: {
    display: "flex",
  },
});

export default function ProductReviewCard(props) {
  const { product } = props;
  const classes = useStyles();
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isContactSellerDialogOpen, setIsContactSellerDialogOpen] = useState(false);
  const [isApproveDialogLoading, setIsApproveDialogLoading] = useState(false);
  const [isRejectDialogLoading, setIsRejectDialogLoading] = useState(false);
  const [isContactSellerDialogLoading, setIsContactSellerDialogLoading] = useState(false);
  const [isDownloadSkuFileLoading, setIsDownloadSkuFileLoading] =
    useState(false);
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const approve = ({ id }) => {
    props.approveProduct({ id });
    setIsApproveDialogLoading(true);
  };

  const reject = ({ id }) => {
    console.log("reason", reason);
    props.rejectProduct({ id, reason: reason });
    setIsRejectDialogLoading(true);
  };

  const resetState = async () => {
    setIsApproveDialogLoading(false);
    setIsApproveDialogOpen(false);
    setIsRejectDialogLoading(false);
    setIsRejectDialogOpen(false);
    setIsContactSellerDialogLoading(false);
    setIsContactSellerDialogOpen(false);
  };

  const openPreviewLink = async (product) => {
    const { response, result } = await getProductById(product.id);
    console.log("openPreviewLink", response, result);
    window.open(result.onlineStorePreviewUrl);
  };

  useEffect(() => {
    resetState();
  }, [props.rejectedResponse, props.approvedResponse, props.createTicketResponse]);

  const downloadSkuFile = ({ sku }) => {
    if (!sku) {
      alert("please enter an sku.");
      return;
    }
    const pwd = window.prompt(
      "You are entering a prohibited area. Please enter password to proceed."
    );
    if (!pwd) {
      alert("Access denied.");
      return;
    }
    setIsDownloadSkuFileLoading(true);
    props.getProductDLink(sku, pwd, setIsDownloadSkuFileLoading, function (r) {
      if (r.result) {
        const url = r.result;
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  };

  const contactSeller = () => {
    props.createTicket({ title, message, product_id: product.id });
    setIsContactSellerDialogLoading(true);
  }

  const downloadFile = (val) => {};

  let previewImage = "https://d3c4b0rnzc49yh.cloudfront.net/p/3dc04545-37f0-41ce-b16e-78186c475ded.jpeg";
  if (product.previewImages) {
    if (Array.isArray(product.previewImages) && product.previewImages.length > 0) {
      previewImage = product.previewImages[0].filepath
    } else {
      previewImage = product.thumbnailSrc
    }   
  }         

  return (
    <div className={classes.profileBox}>
      <img
        className={classes.pic}
        src={ previewImage }
      />
      <div>
        <span> {product.title} </span>
        <Button onClick={() => openPreviewLink(product)}>
          {" "}
          Open Shopify Preview Link{" "}
        </Button>
        <Button onClick={() => downloadSkuFile(product)}>
          {" "}
          Download SKU File{" "}
        </Button>
        <Button onClick={() => setIsContactSellerDialogOpen(true)}>
          {" "}
          Contact Seller{" "}
        </Button>
        <div className={classes.flex}>
          <Button
            className="success"
            onClick={() => setIsApproveDialogOpen(true)}
          >
            {" "}
            Approve{" "}
          </Button>
          <Button
            className="danger"
            onClick={() => setIsRejectDialogOpen(true)}
          >
            {" "}
            Reject{" "}
          </Button>
        </div>
      </div>

      {/* Approve Dialog */}
      <Dialog
        open={isApproveDialogOpen}
        onClose={() => setIsApproveDialogOpen(false)}
      >
        <DialogTitle>Approve Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm approve [{product.title}] ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsApproveDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => approve({ id: product.id })}
            loading={isApproveDialogLoading}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      

      {/* Reject Dialog */}
      <Dialog
        open={isRejectDialogOpen}
        onClose={() => setIsRejectDialogOpen(false)}
      >
        <DialogTitle>Reject Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the reason for rejection for [{product.title}] below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="reason"
            type="text"
            fullWidth
            onChange={(event) => setReason(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRejectDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => reject({ id: product.id })}
            loading={isRejectDialogLoading}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      {/* Contact Seller Dialog */}
      <Dialog
        open={isContactSellerDialogOpen}
        onClose={() => setIsContactSellerDialogOpen(false)}
      >
        <DialogTitle>Contact Seller</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Contact Seller '{product.vendorProfileId}' about '{product.title}':
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="title"
            type="text"
            fullWidth
            onChange={(event) => setTitle(event.target.value)}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="message"
            type="text"
            fullWidth
            onChange={(event) => setMessage(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => setIsContactSellerDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => contactSeller()}
            loading={isContactSellerDialogLoading}
          >
            Send Message to Seller
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
