import React from 'react';
import cx from 'classnames';

const Link = props => {
    return <a
        className={cx('button-link', props.className, props.bold && 'button-link--bold')}
        onClick={props.onClick}>
        {props.text || props.children}
    </a>
}

export default Link;