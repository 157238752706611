import { handleResponse, URLS, getAccessToken } from "../../utils";

export async function getAllReviewableProducts() {
  const req = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
  };
  const url = URLS.PUBLIC_API + "/get-reviewable-products";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function approveProduct({product_id}) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      product_id
    })
  };
  const url = URLS.PUBLIC_API + "/approve-reviewable-product";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function rejectProduct({product_id, reason}) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + getAccessToken(),
    },
    body: JSON.stringify({
      product_id,
      reason
    })
  };
  const url = URLS.PUBLIC_API + "/reject-reviewable-product";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}