export const ROUTES = {
    LOGIN: "/login",
    REGISTER: "/register",
    HOME: "/orders",
    ORDER: "/orders/:orderId",
    CONFIRM: "/confirm",
    CLIENTS_AFFAIRE: "/client-affaire",
    CLIENTS_RESIDENTIELS: "/client-residentiels",
    RESET_PASSWORD: "/reset",
    ADMIN_SETTINGS: "/admin",
    NEW_ACCOUNT: "/new-account",
    SETUP: "/profile-setup",
    PROFILE: "/:code",
    NEWGS: "/new-gs",
    NEWPROFILE: "/new-profile",
    EDITPROFILE: "/edit-profile-old",
    EDITPROFILE_NEW: "/edit-profile",
    PROFILES: "/old_profiles",
    PROFILE_OLD: "/old_profile",
    PROFILE_NEW: "/profile",
    PRODUCTS: "/products",
    INSTRUCTIONS: "/instructions",
    PROFILES_NEW: "/profiles",
    PRODUCT_REVIEW: "/product-review",
    PRODUCT_REVIEW_DETAIL: "/product-review-detail",
    SUPPORT_CHAT: "/support-chat",
};
