import React, { useState } from 'react';
import './reset.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function ResetPass(props) {
  const classes = useStyles();

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const setInput = (fn) => (event) => {
        fn(event.target.value);
  }   

  const submit = (event) => {
    if (!props.location.state || !props.location.state.email ) {
        alert("There was an error, please try logging in again");
        return;
    }

    event.preventDefault();
    if (!code || !password) {
        alert("All fields are required.");
    }
    else if (!password || password.length < 8) {
        alert("password should be atleast 8 characters.");
    }
    else {
        props.resetPassword(props.location.state.email, code, password);
    }
  }

  return (
      <div className="register">
        <Card className={classes.card}>
            <CardMedia
            className={classes.media}
            image="https://images.unsplash.com/photo-1531417666976-ed2bdbeb043b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
            title="Locked"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Reset your password
                </Typography>
                <form className={classes.container} noValidate autoComplete="off" onSubmit={submit}>
                    <TextField
                        label="Reset Code"
                        className={classes.textField}
                        type="text"
                        autoComplete="off"
                        name="code"
                        margin="normal"
                        variant="filled"
                        fullWidth
                        value={code}
                        onChange={setInput(setCode)}
                    />
                    <TextField
                        id="outlined-password-input"
                        label="New Password"
                        className={classes.textField}
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="filled"
                        fullWidth
                        value={password}
                        onChange={setInput(setPassword)}
                    />
                    <div className="form-actions">
                        <Button type="submit" size="small" color="primary">
                            Reset Password
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    </div>
  );
}