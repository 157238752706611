import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DefaultInput from '../../components/text-input';
import { setInput } from '../../utils';
import DefaultButton from '../../components/button';
import InstructionsForm from './instructionsForm';

const useStyles = makeStyles({
    main: {
        margin: "0 auto",
        minWidth: 500
    },
    card: {
        width: '100%',
        marginBottom: "6em"
    },
    media: {
        height: 140,
    },
    title: {
        fontWeight: "bold",
        marginTop: "1em",
        marginBottom: "2em",
    },
    formActions: {
        marginTop: "1.5em"
    },
    searchResult: {
        marginTop: "1em",
        display: "block"
    },
    listItem: {
        display: "flex"
    },
    layers: {
        marginBottom: "5em"
    },
    general: {
        maxWidth: 500,
        margin: "0 auto"
    }
});

export default function Instructions(props) {
    const classes = useStyles();

    const [sku, setSku] = useState("");
    const [newAnimation, setNewAnimation] = useState(false);

    const resetPage = () => {
        setSku("")
        setNewAnimation(false);
        props.clearInstructions();
    }

    const newInstruction = () => {
        setNewAnimation(true);
        props.clearInstructions();
    }

    const discardNewAnimation = () => {
        setNewAnimation(false);
        props.clearInstructions();
    }

    const searchForInstruction = () => {
        if (sku) {
            props.loadAnimation(sku)
        }
    }

    return (
        <div className={classes.main}>
            <div className={classes.card}>
                <Typography className={classes.title} variant="h5" component="h2">
                    Animations Instructions
                </Typography>
                {
                    (!newAnimation && !props.instructions) && <div>
                        <DefaultButton
                            type="button"
                            onClick={newInstruction}
                            className="default-button small-btn">
                            New Animation
                        </DefaultButton>
                        <p> OR </p>
                        <div>
                            <div>
                                <DefaultInput
                                    className={classes.textField}
                                    label="Search by sku"
                                    type="text"
                                    name="layername"
                                    value={sku}
                                    onChange={setInput(setSku)}
                                />

                                <DefaultButton
                                    type="button"
                                    onClick={searchForInstruction}
                                    className="default-button small-btn">
                                    Search
                                </DefaultButton>

                                <button style={{ marginLeft: "1em", border: "none", padding: "1em", borderRadius: "10px", cursor: "pointer" }} onClick={resetPage}>
                                    Reset
                                </button>
                            </div>

                        </div>
                    </div>
                }
                {
                    newAnimation &&
                    <InstructionsForm 
                        discardNewAnimation={discardNewAnimation}
                        newAnimation={newAnimation}
                        saveAnimationInstructions={props.saveAnimationInstructions}
                    />
                }
                {
                    props.instructions && !newAnimation &&
                    <InstructionsForm 
                        instructions={props.instructions}
                        discardNewAnimation={discardNewAnimation}
                        newAnimation={newAnimation}
                        saveAnimationInstructions={props.saveAnimationInstructions}
                    />
                }
            </div>
        </div>
    );
}