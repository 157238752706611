import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { setInput } from '../../utils';
import Button from '../../components/button';
import { ROUTES } from '../../components/router/routes';


const useStyles = makeStyles({
  media: {
    height: 140,
  },
  title: {
    fontWeight: "bold",
  },
  buttons: {
      width: '100%'
  },
  titleContainer: {
    marginBottom: '2em'
  },
  container: {
      width: '100%',
      marginBottom: '3em'
  },
  profileBox: {
    background: '#eee',
    padding: '5px',
    maxWidth: 500,
    margin: '0 auto',
    marginTop: '1em'
  },
  pic: {
      maxHeight: 150,
      borderRadius: '50%',
      border: '3px solid #ccc'
  },
  menu: {
    marginTop: '0.5em'
  }
});

export default function Profiles(props) {
  const PER_PAGE = 20;
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    props.getAllProfiles();
  }, []);
  
  return (
      <div className="profiles">
        <div className={classes.container}>
          <div className={classes.menu}>
            <Link  
              to={{
                pathname: ROUTES.HOME,
              }}
            >
              <Button> Main Menu </Button>
            </Link>
          </div>
          <div>
            <p>Search Profile</p>
            <input value={filter} onChange={ setInput(setFilter) } />
          </div>
            { 
              props.profiles && props.profiles.filter( f => !filter ? true : f.display_name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ).map( profile => (
                <Link  
                    key={profile.id}
                    to={{
                    pathname: ROUTES.PROFILE_OLD,
                    state: { data: profile }
                }}>
                    <div className={classes.profileBox}>
                        <img className={classes.pic} src={profile.picture_url} />
                        <p> {profile.display_name} </p>
                    </div>
                </Link>
              )) 
            }
        </div>
    </div>
  );
}