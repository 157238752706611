import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DefaultInput from '../../components/text-input';
import DefaultButton from '../../components/button';

import CreatableSelect from 'react-select/creatable';

const TYPES = ["Provided Text", "Provided Color", "Picture", "Dynamic Number", "Dynamic Text"].map(v => ({ value: v, label: v }));
const SOCIALS = ["Not Applicable", "YouTube", "TikTok", "Instagram", "Twitter", "Twitch"].map(v => ({ value: v, label: v }));
const FORMATS = [ "username", "sub count" ].map(v=>({ value: v, label: v }))

const useStyles = makeStyles({
    main: {
        margin: "0 auto",
        minWidth: 500
    },
    card: {
        width: '100%',
        marginBottom: "6em"
    },
    media: {
        height: 140,
    },
    title: {
        fontWeight: "bold",
        marginTop: "1em",
        marginBottom: "2em",
    },
    formActions: {
        marginTop: "1.5em"
    },
    searchResult: {
        marginTop: "1em",
        display: "block"
    },
    listItem: {
        display: "flex",
        alignItems: "center"
    },
    layers: {
        marginBottom: "3em"
    },
    general: {
        maxWidth: 500,
        margin: "0 auto"
    }
});

const createOption = (label) => (!label ? {} : {
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});

export default function InstructionsForm(props) {

    const { newAnimation } = props;
    const classes = useStyles();
    let [instructions, setInstructions] = useState(props.instructions || {});
    const layers = instructions.layers || [];
    const scriptParams = instructions.scriptParams || [];

    const removeItemList = (index) => async () => {
        const result = await window.confirm("Are you sure?")
        if (result) {
            let newLayer = [...layers]
            newLayer.splice(index, 1);
            setInstructions({ ...instructions, layers: newLayer });
        }
    }

    const removeScriptParam = (index) => async () => {
        const result = await window.confirm("Are you sure?")
        if (result) {
            let newScriptParams = [...scriptParams]
            newScriptParams.splice(index, 1);
            setInstructions({ ...instructions, scriptParams: newScriptParams });
        }
    }

    const updateInstructions = (property) => (event) => {
        if (property === "script" && !event.target.value) {
            setInstructions({ ...instructions, scriptParams: [], [property]: event.target.value });
        }
        else {
            setInstructions({ ...instructions, [property]: event.target.value });        
        }
    }

    const saveInstructions = () => {
        const data = instructions;

        if (!data.sku || !data.projectFilePath || !data.output || !data.composition) {
            alert("Make sure to fill all inputs.")
            return;
        }

        if (data.layers && data.layers.length > 0) {
            for (let index = 0; index < data.layers.length; index++) {
                const layer = data.layers[index];

                if (!layer.layername || !layer.type || !layer.property || !layer.socials) {
                    alert("layer #" + (index + 1) + " is missing fields.");
                    return;
                }

                if (layer.socials !== "Not Applicable" && !layer.dynamicKey) {
                    alert("layer #" + (index + 1) + " is missing fields. Please specify what key to load the information from.");
                    return;
                }
            }
        }

        if (data.scriptParams && data.scriptParams.length > 0) {
            for (let index = 0; index < data.scriptParams.length; index++) {
                const layer = data.scriptParams[index];

                if (!layer.layername || !layer.type || !layer.property) {
                    alert("script param #" + (index + 1) + " is missing fields.");
                    return;
                }
            }
        }

        if (data.outputKey && data.outputKey !== "index") {
            //validate that the property exists.
            const found = data.layers && data.layers.find(item => item.property === data.outputKey);

            if (!found) {
                alert(data.outputKey + " was not found in the layers. Please double check.");
                return;
            }
        }
        
        props.saveAnimationInstructions(data);
    }

    const updateLayer = (index, property) => (event) => {

        const newLayers = [...layers];

        if (property === "socials") {
            if (event.value === "Not Applicable") {
                newLayers[index]["disabled"] = true;
                newLayers[index]["dynamicKey"] = ""
            }
            else {
                newLayers[index]["disabled"] = false;
            }
        }

        newLayers[index][property] = event.value || event.target.value;
        setInstructions({ ...instructions, layers: newLayers });
    }

    const updateLayerBoolean = (index, property) => (event) => {

        const newLayers = [...layers];

        newLayers[index][property] = !newLayers[index][property];

        setInstructions({ ...instructions, layers: newLayers });
    }

    const updateScriptParams = (index, property) => (event) => {
        const newScriptParams = [...scriptParams];

        if (property === "socials") {
            if (event.value === "Not Applicable") {
                newScriptParams[index]["disabled"] = true;
                newScriptParams[index]["dynamicKey"] = ""
            }
            else {
                newScriptParams[index]["disabled"] = false;
            }
        }

        newScriptParams[index][property] = event.value || event.target.value;
        setInstructions({ ...instructions, scriptParams: newScriptParams });
    }

    const newItemList = () => {
        if (layers.length < 12) {
            setInstructions({ ...instructions, layers: [...layers, {}] });
        }
        else {
            alert("There are too many layers.")
        }
    }

    const newScriptLine = () => {
        if (scriptParams.length < 6) {
            setInstructions({ ...instructions, scriptParams: [...scriptParams, {}] });
        }
        else {
            alert("There are too many script params.")
        }
    }

    return (
        <div>
            {
                !newAnimation && <h3>Instructions for {instructions.sku}</h3>
            }
            {
                newAnimation && <h3>New Animation</h3>
            }

            <h4>1. Enter General Settings </h4>
            <div className={classes.general}>
                <DefaultInput
                    className={classes.textField}
                    label="Product SKU"
                    type="text"
                    name="sku"
                    value={instructions.sku}
                    onChange={updateInstructions("sku")}
                />
                <DefaultInput
                    className={classes.textField}
                    label="Project File Path"
                    type="text"
                    name="filepath"
                    value={instructions.projectFilePath}
                    onChange={updateInstructions("projectFilePath")}
                />
                <DefaultInput
                    className={classes.textField}
                    label="Output File Name"
                    type="text"
                    name="layername"
                    value={instructions.output}
                    onChange={updateInstructions("output")}
                />
                <DefaultInput
                    className={classes.textField}
                    label="Output Key (optional)"
                    type="text"
                    placeholder="index, Username, etc."
                    name="layername"
                    value={instructions.outputKey}
                    onChange={updateInstructions("outputKey")}
                />
                <DefaultInput
                    className={classes.textField}
                    label="Project Composition Name"
                    type="text"
                    name="composition"
                    value={instructions.composition}
                    onChange={updateInstructions("composition")}
                />
            </div>

            <div className={classes.layers}>
                <h4>2. Project File Layers To Change</h4>
                <ul>
                    {layers && layers.map((layer, index) => (<li className={classes.listItem}>
                        <DefaultInput
                            className={classes.textField}
                            label="Layer"
                            type="text"
                            name="layername"
                            value={layer.layername}
                            onChange={updateLayer(index, "layername")}
                        />
                        <div className="default-input-wrapper">
                            {
                                <label>
                                    Layer Type
                                </label>
                            }
                            <CreatableSelect
                                options={TYPES}
                                value={createOption(layer.type)}
                                onChange={updateLayer(index, "type", true)}
                            />
                        </div>

                        <DefaultInput
                            className={classes.textField}
                            label="Property"
                            type="text"
                            name="layername"
                            value={layer.property}
                            onChange={updateLayer(index, "property")}
                        />
                        <div className="default-input-wrapper">
                            {
                                <label>
                                    Format
                                </label>
                            }
                            <CreatableSelect
                                options={FORMATS}
                                value={createOption(layer.format)}
                                onChange={updateLayer(index, "format", true)}
                            />
                        </div>
                        <div className="default-input-wrapper">
                            {
                                <label>
                                    Social
                                </label>
                            }
                            <CreatableSelect
                                options={SOCIALS}
                                value={createOption(layer.socials)}
                                onChange={updateLayer(index, "socials", true)}
                            />
                        </div>
                        <DefaultInput
                            className={classes.textField}
                            label="DynamicKey"
                            disabled={layer.disabled}
                            type="text"
                            name="layername"
                            value={layer.dynamicKey}
                            onChange={updateLayer(index, "dynamicKey")}
                        />
                        <div>
                            <label>
                                Optional
                            </label>
                            <input checked={layer.optional || false} onChange={updateLayerBoolean(index, "optional")} type="checkbox" />
                        </div>
                        <button onClick={removeItemList(index)}>X</button>
                    </li>))}
                </ul>
                <DefaultButton
                    type="button"
                    onClick={newItemList}
                    className="default-button success small-btn">
                    New Layer
                </DefaultButton>
            </div>

            <div className={classes.layers}>
                <h4>3. Script Section (optional)</h4>

                <DefaultInput
                    className={classes.textField}
                    label="Script Path"
                    type="text"
                    name="script"
                    value={instructions.script}
                    onChange={updateInstructions("script")}
                />

                {
                    instructions.script &&
                    <div>
                        <h5>
                            Script Parameters
                        </h5>

                        <ul>
                            {scriptParams && scriptParams.map((layer, index) => (<li className={classes.listItem}>
                                <DefaultInput
                                    className={classes.textField}
                                    label="Parameter Name"
                                    type="text"
                                    name="layername"
                                    value={layer.layername}
                                    onChange={updateScriptParams(index, "layername")}
                                />
                                <div className="default-input-wrapper">
                                    {
                                        <label>
                                            Value Type
                                        </label>
                                    }
                                    <CreatableSelect
                                        options={TYPES}
                                        value={createOption(layer.type)}
                                        onChange={updateScriptParams(index, "type", true)}
                                    />
                                </div>

                                <DefaultInput
                                    className={classes.textField}
                                    label="Property"
                                    type="text"
                                    name="layername"
                                    value={layer.property}
                                    onChange={updateScriptParams(index, "property")}
                                />
                                <button onClick={removeScriptParam(index)}>X</button>
                            </li>))}
                        </ul>
                        <DefaultButton
                            type="button"
                            onClick={newScriptLine}
                            className="default-button success small-btn">
                            New Script Parameter
                        </DefaultButton>

                    </div>
                }
            </div>

            <div>
                {
                    <DefaultButton
                        type="button"
                        style={{ marginRight: "1em" }}
                        onClick={props.discardNewAnimation}
                        className="default-button danger small-btn">
                        {newAnimation ? "Cancel" : "Discard Changes"}
                    </DefaultButton>
                }
                <DefaultButton
                    type="button"
                    onClick={saveInstructions}
                    className="default-button small-btn">
                    Save Instructions
                </DefaultButton>
            </div>
        </div>
    )
}