    import { LOGIN_SUCCESS, REGISTER_SUCCESS, GET_CLIENTS_AFFAIRES_SUCCESS, GET_CLIENTS_RESIDENTIELS_SUCCESS, GET_MAX_LOGIN_ATTEMPTS_SUCCESS, GET_MY_INFO_SUCCESS, LOGOUT } from './actionTypes';

    export default (state = {}, action) => {
    switch (action.type) {
      case LOGIN_SUCCESS: 
        return {
          ...state,
          user: action.user,
          isAuth: true,
        }
      case REGISTER_SUCCESS: 
        return {
          ...state,
          accountInfo: {
            email: action.user.email,
            password: action.user.password,
          },
        }
      case GET_CLIENTS_AFFAIRES_SUCCESS:
        return {
          ...state,
          clientsAff: action.clients,
        }
      case GET_CLIENTS_RESIDENTIELS_SUCCESS:
          return {
            ...state,
            clientsRes: action.clients,
          }
      case GET_MAX_LOGIN_ATTEMPTS_SUCCESS: 
          return {
            ...state,
            maxAttempts: action.count
          }
      case GET_MY_INFO_SUCCESS: 
          return {
            ...state,
            user: action.user
          }
      case LOGOUT:
        return {
          ...state,
          user: undefined,
        }
      default:
        return state;
    }
  };
  