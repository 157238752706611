import { createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import saga from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

//mount saga middleware on the store
const store =  createStore(reducer, applyMiddleware(sagaMiddleware));

// then run the saga
sagaMiddleware.run(saga)

export default store;