import {
  DISMISS_MESSAGES,
  CREATE_TICKET_SUCCESS
} from "./actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_TICKET_SUCCESS:
      return {
        ...state,
        createTicketResponse: action.message,
      };
    case DISMISS_MESSAGES:
      return {
        ...state,
        approvedResponse: null,
        rejectedResponse: null,
        createTicketResponse: null,
      };
    default:
      return state;
  }
};
